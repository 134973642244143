import * as React from 'react';
import {ReactComponent as Money} from 'assets/images/money.svg';
import CustomSpinner from 'utils/Spinner/Spinner';
import {INITIAL} from 'components/constants';

const WaitingForPayment = ({timeLeft, setCurrentStep}) => {
    return (
        <div className="g-bold text--gray">
            <div className="text-center pt-5">
                <span>
                    Please be patient, while we confirm your payment
                    {timeLeft && (
                        <p>
                            <span className="text-warning pe-2">
                                {timeLeft?.minutes}:{timeLeft?.seconds}
                            </span>
                            remaining
                        </p>
                    )}
                </span>
            </div>

            <div className="flex__between my-5">
                <Money className="primary" />
                <div className="full-line" />
                <CustomSpinner cls="text--primary" />
            </div>

            {setCurrentStep && (
                <div className="col-xl-6 col-8 mx-auto">
                    <button
                        className="btn btn--lg btn--primary g-bold"
                        onClick={() => setCurrentStep(INITIAL)}
                    >
                        Back
                    </button>
                </div>
            )}
        </div>
    );
};

export default WaitingForPayment;
