import {PENDING, SUCCESS} from 'components/constants';
import {titleCase} from './helpers';

export const StatusPills = ({cls, status, text}) => {
    const statusBgColor =
        titleCase(status) === titleCase(SUCCESS)
            ? ' pill--success'
            : titleCase(status) === titleCase(PENDING)
            ? ' pill--warning'
            : ' pill--danger';

    return <div className={`text-center ${`w-100`}${statusBgColor} ${cls}`}>{titleCase(text)}</div>;
};
