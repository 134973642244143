import React from 'react';
import {getCurrencySymbol, titleCase, toCurrency} from 'utils/helpers';

const InvoicePrice = ({invoice, currency}) => {
    const {other_info, terms_and_condition} = invoice || {};
    const {tax_value, tax, coupon_value, calculated_items} = other_info || {};

    const subTotal = calculated_items?.reduce((acc, item) => acc + (item?.total || 0), 0);
    const currencyCode = getCurrencySymbol(currency);

    return (
        <div className="flex__between flex-wrap invoice__description row">
            <div className="col-sm-6">
                {terms_and_condition && <p className="fw-bold mb-1"> Terms & Conditions</p>}
                <span>{terms_and_condition}</span>
            </div>
            <div className="col-sm-5 p-3 bg-light">
                <div className="flex__between">
                    <span>Sub Total</span>
                    <p>{`${currencyCode} ${toCurrency(subTotal)}`}</p>
                </div>

                <div className="flex__between">
                    <span>Tax {tax ? `(${titleCase(tax?.type)})` : ''}</span>
                    <p>{`${currencyCode} ${toCurrency(tax_value || 0)}`}</p>
                </div>

                {coupon_value && (
                    <div className="flex__between">
                        <span>Coupon</span>
                        <p>{`${currencyCode} ${toCurrency(coupon_value || 0)}`}</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default InvoicePrice;
