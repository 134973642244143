import {useState} from 'react';
import {Modal, ModalFooter, ModalHeader, ModalBody} from 'reactstrap';
import CustomInputBox from 'utils/CustomInput/CustomInputBox';

const SubscriptionFundWalletModal = ({toggle, handleInitializeCheckout}) => {
    const [amount, setAmount] = useState();

    const handleSubmit = () => {
        handleInitializeCheckout(amount);
        toggle();
    };

    return (
        <Modal toggle={toggle} isOpen={true} centered={true} keyboard={false} backdrop="static">
            <ModalHeader toggle={toggle} className="p-4">
                <span className="fw-bold p-3 fs-4"> Fund Wallet</span>
            </ModalHeader>
            <ModalBody>
                <div className="col-12 my-5 px-4">
                    <CustomInputBox
                        required
                        name="amount"
                        label="Amount (Minimum of 1000)"
                        value={amount}
                        placeholder="Amount"
                        onChange={({target}) => setAmount(target?.value.replace(/[^0-9]/g, ''))}
                    />
                </div>
            </ModalBody>

            <ModalFooter>
                <div className="w-100 p-3 d-flex justify-content-between gap-3">
                    <button className="btn btn--gray rounded-0" onClick={toggle}>
                        Cancel
                    </button>
                    <button
                        className="btn btn--secondary rounded-0 px-4"
                        disabled={!amount || amount < 1000}
                        onClick={handleSubmit}
                    >
                        Continue
                    </button>
                </div>
            </ModalFooter>
        </Modal>
    );
};

export default SubscriptionFundWalletModal;
