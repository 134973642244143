import React from 'react';

const Alert = ({msg, variant = 'danger', onClose, cls}) => {
    return (
        <div>
            <div
                className={`alert alert-${variant} alert-dismissible fade show g-bold ${cls}`}
                role="alert"
            >
                {msg}
                <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="alert"
                    aria-label="Close"
                    onClick={onClose}
                ></button>
            </div>
        </div>
    );
};

export default Alert;
