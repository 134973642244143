import React from 'react';
import Logo from 'assets/images/logo.svg';

const Header = ({LogoURL}) => {
    return (
        <div className="w-100 p-5">
            <img
                src={LogoURL ?? Logo}
                alt=""
                style={{height: '39px', maxWidth: '165px', objectFit: 'contain'}}
            />
        </div>
    );
};

export default Header;
