import {useState} from 'react';
import {QueryClient} from 'react-query';

export const useReactQueryClient = () => {
    const [queryClient] = useState(function () {
        return new QueryClient();
    });

    return queryClient;
};
