import React from 'react';
import WarningIcon from 'assets/images/warning.svg';
import {getCurrencySymbol, toCurrency, titleCase, getNextBillingDate, planMap} from 'utils/helpers';

const SubscriptionSummary = ({paymentData}) => {
    const curr = getCurrencySymbol(paymentData?.metadata?.plan?.currency);
    const title = `${titleCase(paymentData?.metadata?.plan?.name)} 
    (${curr}${paymentData?.amount})`;
    const amount = `${curr} ${toCurrency(paymentData?.amount)}`;
    const discount = `${curr} ${toCurrency(
        paymentData?.couponAmount ? paymentData?.couponAmount : 0
    )}`;
    const total = `${curr} ${toCurrency(paymentData?.amount - paymentData?.couponAmount)}`;
    const billingDate = getNextBillingDate(paymentData?.metadata?.plan?.interval);
    const plan = planMap[paymentData?.metadata?.plan?.interval];

    return (
        <div className="left-content">
            <h1 className="head-1 sub-summary">Subscription Summary</h1>
            <div className="description">
                <h2 className="head-2">{title}</h2>
                <p className="description">
                    This gives you access to all levels and has no restrictions on the level of
                    access you have to all coaching and mentoring session.{' '}
                </p>
            </div>
            <div className="summary-section">
                <h3 className="title">Summary</h3>
                <div className="summary-details">
                    <p className="flex__between">
                        <span>{plan}</span> <span className="amount">{amount}</span>
                    </p>
                    <p className="flex__between">
                        <span>Discount</span> <span className="discount">- {discount}</span>
                    </p>
                </div>
                <div className="billing-section">
                    <p className="flex__between">
                        <span>Total Billed</span> <span className="total">{total}</span>
                    </p>
                    {paymentData?.metadata?.plan?.subscription_type === 'recurring' && (
                        <div className="warning">
                            <img src={WarningIcon} alt={'icon'} />
                            Next billing date {billingDate}
                        </div>
                    )}
                </div>
            </div>
            {(paymentData?.support_email || paymentData?.support_phone_number) && (
                <div className="info">
                    <p>For more information and inquireries:</p>
                    <div className="contact">
                        <p className="mb-1">{paymentData?.support_email}</p>
                        <p>{paymentData?.support_phone_number}</p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SubscriptionSummary;
