import Alert from 'utils/Alert/Alert';
import {AddAccountForm} from './PayWithBankAccountForm/AddAccountForm';
import OTPVerification from 'components/Checkouts/Checkout/common/OTPVerification';
import WaitingForPayment from 'components/Checkouts/Checkout/common/WaitingForPayment';
import {PhoneNumberForm} from './PhoneNumberForm';
import {STEPS, usePayWithBankAccount} from './PayWithBankAccountContext';
import {AccountList} from './PayWithBankAccountForm/AccountList';

const PayWithBankAccount = () => {
    const {
        currentState,
        errorMsg,
        previousState,
        updateCurrentState,
        loading,
        setErrorMsg,
        handleChargeAccount,
    } = usePayWithBankAccount();

    const render = () => {
        switch (currentState) {
            case STEPS.NEW_ACCOUNT:
                return <AddAccountForm />;

            case STEPS.SELECT_OPTIONS:
                return <AccountList />;

            case STEPS.PHONE_VERIFICATION:
            default:
                return <PhoneNumberForm />;

            case STEPS.OTP_VERIFICATION:
                return (
                    <OTPVerification
                        loading={loading}
                        onCancel={() => updateCurrentState(previousState)}
                        handleSubmit={handleChargeAccount}
                    />
                );
            case STEPS.WAITING_FOR_PAYMENT:
                return <WaitingForPayment />;
        }
    };
    return (
        <>
            {errorMsg && <Alert {...errorMsg} onClose={() => setErrorMsg()} />}
            {render()}
        </>
    );
};

export default PayWithBankAccount;
