import React from 'react';
import CustomPhoneInput from 'utils/CustomInput/CustomPhoneInput';
import {getPhone} from 'utils/helpers';
import CustomSpinner from 'utils/Spinner/Spinner';
import {usePayWithBankAccount} from './PayWithBankAccountContext';

export const PhoneNumberForm = () => {
    const {formValues, loading, formErrors, updateData, verifyPhone} = usePayWithBankAccount();

    const handleSubmit = e => {
        e.preventDefault();
        verifyPhone();
    };

    return (
        <form className="row" onSubmit={handleSubmit}>
            <div className="col-12 text-center my-4">
                <span className="g-bold text--gray">Enter bank information to receive OTP</span>
            </div>

            <div className="col-12">
                <CustomPhoneInput
                    cls="mb-4"
                    error={formErrors?.phoneNumber}
                    label="Phone Number"
                    name="phoneNumber"
                    placeholder="Enter Phone Number"
                    onChange={e => updateData({phoneNumber: getPhone(e)})}
                    value={formValues?.phoneNumber}
                />
            </div>

            <div className="col-12 pt-4">
                <button
                    className="btn btn--lg btn--primary g-bold"
                    type="submit"
                    disabled={loading || formValues?.phoneNumber?.length < 9}
                >
                    {loading ? <CustomSpinner /> : 'Continue'}
                </button>
            </div>
        </form>
    );
};
