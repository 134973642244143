import {useState, useContext} from 'react';
import Header from 'components/Common/Header';
import Footer from 'components/Common/Footer';
import CustomInputBox from 'utils/CustomInput/CustomInputBox';
import {CustomCheckBox} from 'utils/CustomInput/CustomCheckBox';
import AppContext from 'components/App/AppContext';
import SubscriptionSummary from './SubscriptionSummary';
import CustomSpinner from 'utils/Spinner/Spinner';
import {getError} from 'utils/helpers';
import Alert from 'utils/Alert/Alert';
import {getCurrencySymbol, toCurrency} from 'utils/helpers';
import {initializeCheckout} from 'api/routes/wallet';

const SubcriptionCardContent = ({paymentData}) => {
    const [saveDetails, setSaveDetails] = useState(true);
    const [errorMsg, setErrorMsg] = useState();
    const [loading, setLoading] = useState(false);

    const {API, apiVersion} = paymentData;
    const {metadata: appMetadata, setCheckoutData} = useContext(AppContext);
    const logo = paymentData?.logo || appMetadata?.company_logo;

    const curr = getCurrencySymbol(paymentData?.metadata?.plan?.currency);
    const total = `${curr} ${toCurrency(paymentData?.amountAfter)}`;

    const setError = message => {
        setLoading(false);
        setErrorMsg(message);
    };

    const handleSubmit = async () => {
        const payload = {...paymentData};
        delete payload?.API;
        delete payload?.apiVersion;
        delete payload?.isPro;
        delete payload?.refetch;

        try {
            setLoading(true);
            setErrorMsg();
            const response = await API.post(
                `${apiVersion}${initializeCheckout}`,
                {
                    walletId: 'master',
                    currency: paymentData?.metadata?.plan?.currency,
                    amount: +paymentData?.amountAfter,
                    metadata: {
                        type: 'subscription',
                        subscription: {
                            automate: saveDetails,
                            ...payload,
                        },
                        customer: paymentData?.metadata?.customer,
                    },
                },
                {headers: {Authorization: paymentData?.publicKey}}
            );

            const {status, data} = response || {};

            if (status === 200 && (data.status || data.success)) {
                setLoading(false);
                setCheckoutData({
                    url: data?.data?.authorization_url,
                });
            } else setError(data?.message);
        } catch (error) {
            setError(getError(error.response?.data || error.response));
        }
    };

    return (
        <>
            <Header
                logo={logo}
                twitterLink={paymentData?.twitter}
                instagramLink={paymentData?.instagram}
                facebookLink={paymentData?.facebook}
            />
            <div className="subscription-content">
                <SubscriptionSummary type="card" paymentData={paymentData} />

                <div className="right-content">
                    {errorMsg && (
                        <Alert msg={errorMsg} cls="px-5 mt-1 w-100" onClose={() => setErrorMsg()} />
                    )}
                    <h1 className="head-1 mb-2">Payment Details</h1>
                    <div className="col-12">
                        <CustomInputBox
                            label="Email Address"
                            name="email"
                            placeholder="Email Address"
                            value={paymentData?.metadata?.customer?.email}
                            onChange={() => {}}
                            disabled
                        />
                    </div>
                    {paymentData?.subscriptionType === 'recurring' && (
                        <div className="col-12">
                            <CustomCheckBox
                                cls="mb-3"
                                label="Save details for next billing date"
                                name="saveCard"
                                checked={saveDetails}
                                onClick={() => setSaveDetails(!saveDetails)}
                            />
                        </div>
                    )}
                    <div className="col-12 pt-3 px-0">
                        <button
                            className="btn btn--lg btn--secondary g-bold"
                            onClick={handleSubmit}
                        >
                            {loading ? <CustomSpinner /> : `  Pay Amount - ${total}`}
                        </button>
                    </div>
                </div>
            </div>
            <Footer logo={logo} poweredBy="Subscriptions" />
        </>
    );
};

export default SubcriptionCardContent;
