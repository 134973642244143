import React from 'react';
import './CustomInput.scss';

const CustomInputBox = ({
    type = 'text',
    name,
    label,
    placeholder,
    value,
    readonly,
    error,
    icon,
    iconText,
    onChange,
    onFocus,
    required,
    pattern,
    cls,
    inputRef,
    disabled,
    ...rest
}) => {
    const isError = error ? 'error' : ' ';
    const isRequired = required ? 'required' : ' ';
    const isDisabled = disabled ? 'disabled' : ' ';

    return (
        <div className={`form-group ${cls}`}>
            {label && (
                <label htmlFor={name} className={`form-group-label ${isRequired}`}>
                    {label}
                </label>
            )}
            <div className={`input-wrapper d-flex align-items-center ${isDisabled} ${isError} `}>
                {icon && <div className="px-4">{icon}</div>}
                <input
                    type={type}
                    // id={name}
                    ref={inputRef}
                    name={name}
                    placeholder={placeholder}
                    value={value}
                    readOnly={readonly}
                    autoComplete="off"
                    disabled={disabled}
                    onChange={onChange}
                    pattern={pattern}
                    {...rest}
                />
                {iconText && <div className="icon__text">{iconText}</div>}
            </div>
            {typeof error === 'string' && <small className="input-text-error">{error}</small>}
        </div>
    );
};

export default CustomInputBox;
