import styledComponents from 'styled-components';

export const PaymentPageWrapper = styledComponents.div`
  .bg--secondary,
  .btn--secondary,
	.btn--secondary:hover {
    background: ${({colors}) => colors?.primaryColor};
  } 
	.bg--primary,
	.btn--primary,
	.btn--primary:hover {
		background: ${({colors}) => colors?.secColor};
	}
    .quantity-counter{
        gap : 23px;
       .plus{
         width: 22px;
        height: 22px;
        background: #C7D6E3;
       }
       .count{
        font-size: 14px;
        font-weight: 600;
        line-height: 14px;
       }
    }
    .payment-page-container{
        height: 100vh;
        position: relative;
        .left {
            height : 100%;
        }
        .right{
            height : 100%;
        }
        .page-container{
            position: absolute;
            top: 5%;
            left: 12%;
            width: 75%; 
        }
    }

    @media (max-width: 991px) {
     &.checkout{
        position: relative;
        overflow : auto;
     }
       
    .payment-page-container{
        flex-direction: column;
        .left {
            height : 60vh;
        }
        .right{
            height : 47vh;
        }

        .page-container{
            left: 5%;
            width : 90%;
            height: 100vh;
            overflow-y: scroll;
            scrollbar-width: none;
            &::-webkit-scrollbar {
                display: none;
            }
            .form-content.flex__between{
                flex-direction : column;
            }
        }

    }

    }
`;
