import React from 'react';

const StatusMessage = ({title, message, btnName, handleClick}) => {
    return (
        <div className="text-center">
            <h3 className="fw-bold mt-4">{title}</h3>
            <p className="pb-4 pt-3 text--gray">{message}</p>
            {btnName && (
                <button className="btn btn--gray mx-auto" onClick={handleClick}>
                    {btnName}
                </button>
            )}
        </div>
    );
};

export default StatusMessage;
