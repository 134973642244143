import React from 'react';
import {getStatus} from 'components/Invoices/extra';
import {StatusPills} from 'utils/StatusPills';
import {getCurrencySymbol, timeDateFormat, toCurrency} from 'utils/helpers';

const InvoiceDescription = ({invoice}) => {
    const {invoice_reference, amount, customer, description, due_date, issued_date, partial} =
        invoice || {};

    const {checkoutData} = invoice?.metadata || {};
    const currency = getCurrencySymbol(checkoutData?.currency);

    const date = timeDateFormat(due_date);
    const issuedDate = timeDateFormat(issued_date);

    const totalPaid = partial?.installments?.reduce((acc, curr) => acc + curr, 0) || 0;

    return (
        <div className="invoice__description">
            <section className="flex__between">
                <div>
                    <span>Invoice to</span>
                    <p>{`${customer?.first_name} ${customer?.last_name} - ${customer?.email}`}</p>
                </div>
                <div className="text-end">
                    <h2>Invoice {invoice_reference}</h2>
                    <StatusPills {...getStatus(invoice?.status)} />,
                </div>
            </section>
            <section className="flex__between">
                <div>
                    <span>Description</span>
                    <p className="mb-0">{description}</p>
                </div>
                <div className="text-end">
                    <span>Issue Date</span>
                    <p>{issuedDate ? `${issuedDate?.date} ${issuedDate?.time}` : 'Not Yet'}</p>
                </div>
            </section>

            <section className="flex__between">
                <div>
                    <span>Total Amount</span>
                    <h2 className="mb-0">
                        {currency} {toCurrency(amount)}
                    </h2>
                    {partial?.is_partial && (
                        <strong>
                            (Paid: {currency}
                            {toCurrency(totalPaid)})
                        </strong>
                    )}
                </div>
                <div className="text-end">
                    <span>Due Date</span>
                    <p>{date ? `${date?.date} ${date?.time}` : 'None'}</p>
                </div>
            </section>
        </div>
    );
};

export default InvoiceDescription;
