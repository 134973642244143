import React from 'react';
import TableCell from './TableCell';

const TableRow = ({rowValues, header, id, rowClick}) => {
    const tag = header ? 'th' : 'td';
    const rowSelectID = rowValues?.id ? rowValues?.id : id;
    return (
        <tr
            id={id}
            onClick={() => rowClick(rowSelectID, rowValues)}
            className={rowClick ? 'pointer' : ''}
        >
            {Object.values(rowValues).map((cellValues, i) => (
                <TableCell key={i} cellValues={cellValues} tag={tag} />
            ))}
        </tr>
    );
};

export default TableRow;
