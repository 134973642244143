import * as React from 'react';
import CheckoutWrapper from './CheckoutWrapper';
import {ReactComponent as Danger} from 'assets/images/danger.svg';
import StatusMessage from './StatusMessage';
import {pageInIframe} from 'utils/helpers';

const InitializeError = ({
    title = 'Error Initializing Transaction',
    errorMsg = 'This transaction is either invalid or already completed.',
    retry,
    btnName = 'Retry',
    sendEvent,
}) => {
    const [isIframe, setIframe] = React.useState(false);

    React.useEffect(() => {
        if (typeof window !== 'undefined') {
            setIframe(pageInIframe());
        }
    }, []);

    return (
        <CheckoutWrapper isFrame={isIframe} isError sendEvent={sendEvent}>
            <div className="mx-auto">
                <Danger width={30} height={30} />
                <StatusMessage
                    title={title}
                    message={errorMsg}
                    btnName={`${isIframe ? btnName : ''}`}
                    handleClick={retry}
                />
            </div>
        </CheckoutWrapper>
    );
};

export default InitializeError;
