import React, {useContext} from 'react';
import {getCurrencySymbol, titleCase, toCurrency} from 'utils/helpers';
import ProductItem from './ProductItem';
import {PaymentPageContext} from './PaymentPageContainer';

const Details = ({paymentData}) => {
    const {formValues} = useContext(PaymentPageContext);

    return (
        <div>
            <h2 className="heading my-0">{titleCase(paymentData?.title)}</h2>

            <p className="sub-text my-4">{paymentData?.description}</p>

            <div className="overflow-x flex__between gap-5">
                {paymentData?.image_urls?.length > 0 &&
                    paymentData?.image_urls.map(url => (
                        <div className=" payment-page-images ">
                            <img src={url} alt="" />{' '}
                        </div>
                    ))}
            </div>

            {paymentData?.page_type === 'recurring' ? (
                <div className="preview-plans mb-5">
                    {paymentData?.products?.map((plan, index) => (
                        <div className="my-4" key={index}>
                            <h5 className="heading-3">
                                Plan {index + 1} : {titleCase(plan?.name)} (
                                {getCurrencySymbol(plan?.currency)}
                                {toCurrency(plan?.amount)})
                            </h5>
                            <p className="sub">{plan?.description}</p>
                        </div>
                    ))}
                </div>
            ) : paymentData?.page_type === 'product' ? (
                <div className="preview-plans mb-5">
                    {formValues?.products?.map(prod => (
                        <ProductItem
                            key={prod?.reference}
                            prod={prod}
                            customer_edit_product_quantity={
                                paymentData?.customer_edit_product_quantity
                            }
                        />
                    ))}
                </div>
            ) : (
                <></>
            )}

            {paymentData?.metadata?.merchant?.email ||
                (paymentData?.metadata?.merchant?.phone_number && (
                    <div className="my-5">
                        <h5 className="heading-1 my-2 mb-3">For more information and inquiries:</h5>
                        <p className="contact-text my-2">
                            {paymentData?.metadata?.merchant?.email}
                        </p>
                        <p className="contact-text  my-2">
                            {paymentData?.metadata?.merchant?.phone_number}
                        </p>
                    </div>
                ))}
        </div>
    );
};

export default Details;
