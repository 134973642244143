import {useState, createContext, useContext, useCallback} from 'react';
import {
    initiateBankAccountCharge,
    resolveBank,
    chargeAccount,
    verifyPhoneNumber,
} from 'api/routes/wallet';
import {
    payWithBankAccountDefaultValues,
    stagingBankAccount,
} from 'components/Checkouts/Checkout/extra';
import {responseStatus} from 'components/constants';
import {getError} from 'utils/helpers';

const {IDLE, LOADING, SUCCESS} = responseStatus;

export const STEPS = {
    PHONE_VERIFICATION: 'Phone',
    SELECT_OPTIONS: 'SelectOptions',
    NEW_ACCOUNT: 'NewAccount',
    OTP_VERIFICATION: 'OTPVerification',
    WAITING_FOR_PAYMENT: 'WaitingForPayment',
};

export const PayWithBankAccountContext = createContext({});
export const usePayWithBankAccount = () => useContext(PayWithBankAccountContext);

export const PayWithBankAccountProvider = ({checkoutData = {}, children}) => {
    const [currentState, setCurrentState] = useState(STEPS.PHONE_VERIFICATION);
    const [previousState, setPreviousState] = useState(STEPS.PHONE_VERIFICATION);
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [formErrors, setFormErrors] = useState({});
    const [bankDetails, setBankDetails] = useState({});
    const [verifyAccountStatus, setVerifyAccountStatus] = useState(IDLE);
    const [formValues, setFormValues] = useState(payWithBankAccountDefaultValues);

    const {accountNumber, bank} = formValues;
    const {metadata, API, apiVersion} = checkoutData;
    const Authorization = metadata?.merchant?.publicKey;
    const config = {headers: {Authorization}};
    const isTestEnv = process.env.REACT_APP_ENVIRONMENT === 'test';

    const updateCurrentState = newState => {
        setLoading(false);
        setErrorMsg('');
        setPreviousState(currentState);
        setCurrentState(newState);
    };

    const updateData = (data = {}) => {
        setFormValues(prev => ({
            ...prev,
            ...data,
        }));
    };

    const updateError = name => {
        setFormErrors(prev => ({
            ...prev,
            [name]: '',
        }));
    };

    const handleError = message => {
        setLoading(false);
        setErrorMsg({msg: message});
    };

    const reset = () => {
        setLoading(false);
        setErrorMsg('');
        setCurrentState(STEPS.PHONE_VERIFICATION);
        setFormValues(payWithBankAccountDefaultValues);
    };

    const payload = {
        phoneNumber: isTestEnv ? stagingBankAccount.phoneNumber : formValues?.phoneNumber,
        bankCode: isTestEnv ? stagingBankAccount.bankCode : bank?.bankCode,
        bankName: isTestEnv ? stagingBankAccount.bankName : bank?.bankName,
        accountNumber: isTestEnv ? stagingBankAccount.accountNumber : accountNumber,
        sessionId: formValues?.sessionId,
        customerEmail: metadata.merchant.email,
        checkoutReference: checkoutData?.reference,
        amount: +checkoutData?.amount,
        narration: formValues?.narration
            ? formValues?.narration
            : `${formValues?.accountNumber} ${formValues?.bankName}`,
    };

    const resolveAccount = useCallback(
        async (setAccountName, account) => {
            try {
                setVerifyAccountStatus(LOADING);
                setErrorMsg();
                const payload = account ?? {
                    bank_code: bank?.bankCode,
                    account_number: accountNumber,
                };

                if (isTestEnv) {
                    setVerifyAccountStatus(SUCCESS);
                    updateData({customerName: stagingBankAccount.accountName});
                } else {
                    const response = await API.post(`${apiVersion}${resolveBank}`, payload, config);

                    const {status, data} = response || {};

                    if (status === 200 && (data.status || data.success)) {
                        if (setAccountName) setAccountName(data.data?.account_name);
                        else {
                            setVerifyAccountStatus(SUCCESS);
                            updateData({customerName: data.data?.account_name});
                        }
                    } else {
                        setVerifyAccountStatus(IDLE);
                        !setAccountName && handleError('Error Validating Account. Try Again !!!');
                    }
                }
            } catch (e) {
                setVerifyAccountStatus(IDLE);
                !setAccountName && handleError('Error Validating Account. Try Again !!!');
            }
        },
        [bank, accountNumber]
    );

    const verifyPhone = async () => {
        try {
            setLoading(true);
            setErrorMsg();

            let {phoneNumber} = formValues;
            phoneNumber = isTestEnv ? stagingBankAccount.phoneNumber : phoneNumber;

            const response = await API.post(
                `${apiVersion}${verifyPhoneNumber}`,
                {phoneNumber},
                config
            );

            const {status, data} = response || {};

            if ((status === 201 || status === 200) && (data.status || data.success)) {
                setBankDetails(data?.data);
                updateData({sessionId: data?.data?.sessionId});
                updateCurrentState(
                    data?.data?.accounts?.length === 0 ? STEPS.NEW_ACCOUNT : STEPS.SELECT_OPTIONS
                );
            } else handleError(data?.message);
        } catch (error) {
            handleError(getError(error.response.data));
        }
    };

    const initiateCharge = async (accountInfo = {}) => {
        try {
            setLoading(true);
            setErrorMsg();

            const response = await API.post(
                `${apiVersion}${initiateBankAccountCharge}`,
                {...payload, ...accountInfo},
                config
            );

            const {status, data} = response || {};

            if ((status === 201 || status === 200) && (data.status || data.success)) {
                updateData({
                    bank: accountInfo,
                    accountNumber: formValues?.accountNumber,
                    transReference: data?.data?.reference,
                    sessionId: data?.data?.sessionId,
                });

                updateCurrentState(STEPS.OTP_VERIFICATION);
            } else handleError(data?.message);
        } catch (error) {
            handleError(getError(error.response.data));
        }
    };

    const handleChargeAccount = async otp => {
        try {
            setLoading(true);
            setErrorMsg();

            const newPayload = {...payload, otp, transReference: formValues?.transReference};

            const response = await API.post(`${apiVersion}${chargeAccount}`, newPayload, config);

            const {status, data} = response || {};

            if ((status === 200 || status === 201) && (data.status || data.success)) {
                updateCurrentState(STEPS.WAITING_FOR_PAYMENT);
            } else handleError(data?.message);
        } catch (error) {
            handleError(getError(error.response.data));
        }
    };

    return (
        <PayWithBankAccountContext.Provider
            value={{
                config,
                verifyAccountStatus,
                previousState,
                currentState,
                loading,
                errorMsg,
                setErrorMsg,
                formErrors,
                formValues,
                updateError,
                updateData,
                reset,
                initiateCharge,
                handleChargeAccount,
                verifyPhone,
                updateCurrentState,
                bankDetails,
                resolveAccount,
                setLoading,
                setVerifyAccountStatus,
            }}
        >
            {children}
        </PayWithBankAccountContext.Provider>
    );
};
