import styled from 'styled-components';

export const CheckoutLayout = styled.section`
    .bg--primary,
    .btn--primary,
    .btn--primary:hover {
        background: ${({colors}) => colors?.primaryColor};
    }

    .card__method {
        &.active {
            color: ${({colors}) => colors?.primaryColor} !important;
            border: 2px solid ${({colors}) => colors?.primaryColor} !important;

            svg path {
                fill: ${({colors}) => colors?.primaryColor} !important;
            }

            .icon {
                background: ${({colors}) => colors?.primaryColor} !important;
            }
        }
    }

    .cash {
        color: ${({colors}) => colors?.primaryColor} !important;
    }
    .text--primary {
        color: ${({colors}) => colors?.primaryColor} !important;
    }

    .checkout__header {
        background-color: ${({colors}) => colors?.primaryColor} !important;
    }
`;
