const CryptoJS = require('crypto-js');

const secretKey = process.env.REACT_APP_CRYPTO_ENCRYPT_KEY;

export const fetchLocalUserData = ({storeKey = 'localUserData'}) => {
    try {
        let userData = localStorage.getItem(storeKey);

        if (userData) {
            const bytes = CryptoJS.AES.decrypt(userData, secretKey);
            const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            return decryptedData;
        }
        return {};
    } catch (error) {
        throw new Error('Local storage permission is needed');
    }
};

export const storeLocalUserData = ({userData, storeKey = 'localUserData', clean = false}) => {
    var cipherText = null;
    if (clean) {
        const data = JSON.stringify({...userData});
        cipherText = CryptoJS.AES.encrypt(data, secretKey).toString();
    } else {
        let prevData = fetchLocalUserData({storeKey});
        const data = JSON.stringify({...prevData, ...userData});
        cipherText = CryptoJS.AES.encrypt(data, secretKey).toString();
    }
    try {
        localStorage.setItem(storeKey, cipherText);
    } catch (error) {
        throw new Error('Local storage permission is needed');
    }
};

export const deleteLocalUserData = ({storeKey = 'localUserData'}) => {
    try {
        localStorage.removeItem(storeKey);
    } catch (error) {
        throw new Error('Local storage permission is needed');
    }
};
