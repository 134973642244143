import {useState} from 'react';
import {useMutation} from 'react-query';
import {useHistory} from 'react-router-dom';
import {QR_CODE} from 'components/constants';
import {PaymentForm} from 'components/Common/PaymentForm';
import {initializeQrCode} from 'api/routes/wallet';
import {getError} from 'utils/helpers';

export const QrCodeForm = ({paymentData = {}}) => {
    const history = useHistory();
    const [errorMsg, setErrorMsg] = useState();
    const {reference, API, apiVersion} = paymentData;

    const initializeCheckout = useMutation(
        async payload => {
            setErrorMsg();
            const response = await API.post(initializeQrCode(reference, apiVersion), payload, {});
            return response.data;
        },
        {
            onSuccess: data => {
                const link = apiVersion ? '/payments/' : '/';
                if (data.status || data.success) {
                    history.push(`${link}${data?.data?.reference}`);
                } else setErrorMsg(data?.message);
            },
            onError: error => setErrorMsg(getError(error?.response?.data ?? error)),
        }
    );

    return (
        <PaymentForm
            paymentData={paymentData}
            type={QR_CODE}
            errorMsg={errorMsg}
            initiateQuery={initializeCheckout}
        />
    );
};
