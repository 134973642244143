import * as React from 'react';
import Iframe from 'react-iframe';
import {usePayWithCard} from './PayWithCardContext';

const PaymentConfirmationFrame = () => {
    const {url} = usePayWithCard();

    return (
        <div className="confirmation__frame__wrapper">
            <Iframe url={url} id="payment" className="confirmation__frame" />
        </div>
    );
};

export default PaymentConfirmationFrame;
