import React from 'react';
import Table from 'utils/Table/Table';
import {InvoiceHeadings} from 'components/Invoices/extra';
import {getCurrencySymbol, toCurrency} from 'utils/helpers';

const ItemsTable = ({items = [], currency}) => {
    const getTableData = () => {
        return items?.map(item => ({
            name: item?.name,
            amount: `${getCurrencySymbol(currency)} ${toCurrency(item?.amount || 0)}`,
            quantity: item?.total / item?.amount,
            total: `${getCurrencySymbol(currency)} ${toCurrency(item?.total || 0)}`,
        }));
    };

    return (
        <>
            <div className="pt-5 overflow-x">
                <Table
                    data={getTableData()}
                    headings={InvoiceHeadings}
                    cls="full__height faint__bg"
                    rowClick={() => {}}
                />

                {items?.length === 0 && (
                    <p className="text-danger text-center my-5 fw-bold">
                        No Item Found in this invoice
                    </p>
                )}
            </div>
        </>
    );
};

export default ItemsTable;
