import {createContext, useMemo, useState, useEffect, useContext} from 'react';
import Details from './Details';
import PageForm from './PageForm';
import Header from 'components/Common/Header';
import Footer from 'components/Common/Footer';
import {initialValues} from './utils';
import AppContext from 'components/App/AppContext';

export const PaymentPageContext = createContext({
    formValues: '',
    setFormValues: () => {},
});

export const usePaymentPage = () => useContext(PaymentPageContext);

const PaymentPageContainer = ({paymentData}) => {
    const {metadata} = useContext(AppContext);
    const [formValues, setFormValues] = useState({
        ...initialValues,
        products: [],
    });

    const logo = paymentData?.logo || metadata?.company_logo;

    const values = useMemo(
        () => ({
            formValues,
            setFormValues,
        }),
        [formValues]
    );

    const getMinimumQty = range => {
        return parseInt(range?.split('-')[0]) || 1;
    };

    useEffect(() => {
        if (paymentData?.products?.length > 0) {
            setFormValues(prev => ({
                ...prev,
                products:
                    paymentData?.products?.map(prod => ({
                        ...prod,
                        quantity: getMinimumQty(prod?.range),
                    })) || [],
            }));
        }
    }, [paymentData?.products?.length]);

    return (
        <PaymentPageContext.Provider value={values}>
            <Header
                logo={logo}
                instagramLink={paymentData?.instagram}
                facebookLink={paymentData?.facebook}
                twitterLink={paymentData?.twitter}
            />
            <div className="flex__between form-content">
                <div className="col-12 col-lg-7 bg-white details">
                    <Details paymentData={paymentData} />
                </div>
                <div className="col-12 col-lg-5 form">
                    <PageForm paymentData={paymentData} />
                </div>
            </div>
            <Footer logo={logo} poweredBy="Payment Page" />
        </PaymentPageContext.Provider>
    );
};

export default PaymentPageContainer;
