import React from 'react';
import {PaymentPageWrapper} from '../PaymentPage/styled.payment-page';

const Layout = ({children, colors}) => {
    return (
        <PaymentPageWrapper colors={colors} className="checkout">
            <div className="payment-page-container flex__between">
                <div className="left col-12 col-lg-7 bg--secondary" />
                <div className="right col-12 col-lg-5 bg--primary" />
                <div className="page-container">{children}</div>
            </div>
        </PaymentPageWrapper>
    );
};

export default Layout;
