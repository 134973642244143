import React from 'react';
import PayWithUSSDForm from './PayWithUSSDForm/PayWithUSSDForm';
import CountDownPaymentWrapper from 'components/Checkouts/Checkout/common/CountDownPaymentWrapper';

const PayWithUSSD = ({checkoutData, setBank, refetch}) => {
    return (
        <CountDownPaymentWrapper
            refetch={refetch}
            setBank={setBank}
            checkoutData={checkoutData}
            Component={PayWithUSSDForm}
        />
    );
};

export default PayWithUSSD;
