import moment from 'moment';
import {paymentAPI, walletAPI} from 'api';
import {PAYMENT, currencies} from 'components/constants';

export const getError = error => {
    const {message, data} = error || {};

    let errorMessage = '';

    if (error) {
        if (data?.message) errorMessage = data.message;
        else if (message) errorMessage = message;
        else errorMessage = 'Error Processing Request. Try again.';
    } else {
        errorMessage = 'Error Processing Request. Try again.';
    }

    return errorMessage.replace(/[^a-z0-9+]+/gi, ' ');
};

export const truncate = (str, len = 50) => {
    if (str?.length > len) {
        return str?.substring(0, len) + '...';
    } else {
        return str;
    }
};

export const titleCase = string => {
    if (string === null || string === undefined) {
        return '';
    } else {
        let text = string.toLowerCase().split('_');
        for (var i = 0; i < text.length; i++) {
            text[i] = text[i][0]?.toUpperCase() + text[i]?.slice(1);
        }
        return text.join(' ');
    }
};

export const textOnly = string => {
    if (string === '' || string === null || string === undefined) {
        return '';
    } else {
        let text = string.replace(/[^a-zA-Z]/g, ' ');
        return titleCase(text);
    }
};

export const pureString = text => {
    const regex = /\\n|\\r\\n|\\n\\r|\\r/g;
    return text.replace(regex, '');
};

export const preText = text => {
    const regex = /\\n|\\r\\n|\\n\\r|\\r/g;
    return text.replace(regex, '<br />');
};

export const kebabCase = str =>
    str
        .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
        .join('-')
        .toLowerCase();

export const toCurrency = n => {
    if (n === null || n === undefined || n === 'NaN') return 0;
    return Number(n)
        .toFixed(2)
        .replace(/./g, function (c, i, a) {
            return i > 0 && c !== '.' && (a.length - i) % 3 === 0 ? ',' + c : c;
        });
};

export const timeDateFormat = (obj, format = 'YYYY-MM-DD') => {
    if (!obj || obj === undefined) return '';

    const timeAgo = moment(obj).fromNow();

    const newObj = new Date(obj);

    const time = moment(newObj).format('hh:mm:ss a');
    const timeOnly = moment(newObj).format('hh:mm:ss');
    const date = moment(newObj).format(format);

    return {time, date, timeOnly, timeAgo};
};

export const timeRangeFormat = (obj, init) => {
    if (!obj) return null;

    const newDate = new Date(obj);
    init ? newDate.setHours(0, 0, 0, 0) : newDate.setHours(23, 59, 59, 0);
    return newDate;
};

export const dateToString = obj => {
    if (!obj) return null;

    const newDate = new Date(obj);
    const date = moment(newDate).format('LL');
    return date;
};

export const checkSameDay = (first, second) =>
    first?.getFullYear() === second?.getFullYear() &&
    first?.getMonth() === second?.getMonth() &&
    first?.getDate() === second?.getDate();

export const copyToClipboard = value => {
    const tempInput = document.createElement('input');
    tempInput.value = value;
    document.body.appendChild(tempInput);
    tempInput.select();
    tempInput.focus();
    document.execCommand('copy');
    document.body.removeChild(tempInput);
    return true;
};

export const openLink = (link, target) => {
    const a = document.createElement('a');
    a.setAttribute('href', link);
    a.setAttribute('target', target);
    a.click();
    document.body.removeChild(a);
};

export const getVersion = location => {
    if (!location) return {};
    const {pathname} = location || {};
    const isPro = pathname.includes(PAYMENT);
    const API = isPro ? paymentAPI : walletAPI;
    const apiVersion = isPro ? '/api/v1' : '';
    return {API, apiVersion, isPro};
};

export const getPhone = phone => {
    if (phone) return phone.charAt(0) === '+' ? phone : `+${phone}`;
    return '';
};

export const planMap = {
    daily: '1 Day plan',
    weekly: '1 Week plan',
    monthly: '1 Month plan',
    quarterly: '3 Months plan',
    biannually: '6 Months plan',
    annually: '1 year plan',
};

const intervalMap = {
    daily: {count: 1, type: 'days'},
    weekly: {count: 7, type: 'days'},
    monthly: {count: 1, type: 'months'},
    quarterly: {count: 1, type: 'quarters'},
    biannually: {count: 6, type: 'months'},
    annually: {count: 1, type: 'years'},
};

const getBillingDate = ({count, type = 'days'}) => moment().add(count, type).format('lll');

export const getNextBillingDate = interval => {
    if (!interval) {
        return '';
    }
    const dateInfo = intervalMap[interval];
    const nextBillingDate = getBillingDate(dateInfo);
    return nextBillingDate;
};

export const pageInIframe = () => {
    return window?.location !== window?.parent?.location;
};

export const isParams = str => {
    if (str) {
        const url = new URL(str);
        const searchParams = Boolean(url?.searchParams?.size);
        return searchParams;
    }
    return false;
};

export const getOrigin = () => {
    if (typeof window !== 'undefined') {
        const hostname = window.location?.hostname;
        return hostname === 'localhost'
            ? 'https://dev-checkout.blusalt.net'
            : window.location.origin;
    }
    return '';
};

export const isObject = obj => {
    return Object.prototype.toString.call(obj) === '[object Object]';
};

export const appendPlusToNumber = number => {
    if (!number) return;
    const cleanedNumber = number.replace(/\s/g, '').replace(/^\+/, '');

    if (!cleanedNumber) return;

    return `+${cleanedNumber}`;
};

export const currenciesEnum = {
    USD: '$',
    EUR: '€',
    CRC: '₡',
    GBP: '£',
    ILS: '₪',
    INR: '₹',
    JPY: '¥',
    KRW: '₩',
    NGN: '₦',
    PHP: '₱',
    PLN: 'zł',
    PYG: '₲',
    THB: '฿',
    UAH: '₴',
    VND: '₫',
};

export const getCurrencySymbol = (currency = 'NGN') => {
    return currenciesEnum?.[currency] || currency;
};
