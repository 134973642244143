import securedPayment from 'assets/images/secured-payments.svg';
import BlusaltLogo from 'assets/images/logo-blue.svg';

const Footer = ({logo, poweredBy, cls = ''}) => {
    return (
        <div className={`footer flex__between ${cls}`}>
            <div className="col-12 col-lg-6 ">
                <img
                    src={logo ? logo : BlusaltLogo}
                    alt={''}
                    className="logo img-fluid"
                    style={{height: '39px', objectFit: 'contain'}}
                />
                <div className="">
                    This page is secured by Blusalt Payment Pro {poweredBy}. Go to{' '}
                    <a href="https://payment.blusalt.net" target="_blank" rel="noopener noreferrer">
                        Payment pro
                    </a>{' '}
                    to get started
                </div>
            </div>
            <div className="mb-4 mb-md-0">
                <img src={securedPayment} width="" className="img-fluid" alt="" />
            </div>
        </div>
    );
};

export default Footer;
