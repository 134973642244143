import {useState, useEffect} from 'react';
import Iframe from 'react-iframe';
import {useLocation} from 'react-router-dom';
import {responseStatus} from 'components/constants';
import BigSpinner from 'utils/Spinner/BigSpinner';
import {paymentAPI} from 'api';
import {getMetadata} from 'api/routes/common';
import {getOrigin, getVersion} from 'utils/helpers';
import {ScrollToTop} from 'utils/ScrollToTop';
import {AppMeta} from './AppMeta';
import AppContext from './AppContext';

const {LOADING, IDLE, SUCCESS} = responseStatus;

const AppWrapper = ({children}) => {
    const [origin] = useState(getOrigin());
    const [status, setStatus] = useState(IDLE);
    const [metadata, setMetadata] = useState({});
    const [checkoutData, setCheckoutData] = useState({});

    const {url, refetch} = checkoutData;

    const location = useLocation();
    const {isPro} = getVersion(location);
    const callMeta = isPro || location?.pathname?.startsWith('/invoice');

    const handleMetadata = async () => {
        try {
            if (!callMeta) return setStatus(SUCCESS);

            const response = await paymentAPI.get(getMetadata, {
                params: {website_url: origin},
            });

            const {status, data} = response || {};

            if (status === 200 && (data.status || data.success)) {
                if (data?.data) {
                    setMetadata(data?.data);
                }
            }
            setStatus(SUCCESS);
        } catch (error) {
            setStatus(SUCCESS);
        }
    };

    useEffect(() => {
        handleMetadata();
    }, []);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener('message', receiveMessage, false);
        }
        // eslint-disable-next-line
    });

    // Check Checkout Event
    const receiveMessage = async event => {
        const {data: response, origin} = event || {};

        // if (origin === window?.origin) {
        const {name} = response || {};

        if (name === 'close') setCheckoutData({});
        else if (name === 'done') {
            refetch?.();
            setCheckoutData({});
        }
        // }
    };

    const renderByStatus = () => {
        switch (status) {
            case LOADING:
                return (
                    <div className="mt-5 pt-5 flex__center  invoice__content">
                        <BigSpinner />;
                    </div>
                );

            case SUCCESS:
                return children;
            default:
                return '';
        }
    };

    const colors = {
        primaryColor: metadata?.brand_primary_color,
        secColor: metadata?.brand_secondary_color,
    };

    const favicon = metadata?.company_icon ?? '/favicon.png';

    return (
        <>
            <AppContext.Provider value={{metadata, favicon, colors, setCheckoutData}}>
                <AppMeta linkFavicon={favicon} />
                {renderByStatus()}
                <ScrollToTop />
            </AppContext.Provider>
            {url && <Iframe url={url} id="payment" />}
        </>
    );
};

export default AppWrapper;
