import React from 'react';
import TableRow from './TableRow';

const TableHead = ({headings}) => (
    <thead className="table__head">
        <TableRow rowValues={headings} header />
    </thead>
);

export default TableHead;
