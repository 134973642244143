import {useState, createContext, useContext, useRef} from 'react';
import {chargeTokenizedCard, getTokenizeCard, initiateCard} from 'api/routes/wallet';
import * as utils from 'components/Checkouts/Checkout/extra';
import {responseStatus} from 'components/constants';
import {getError} from 'utils/helpers';
import {fetchLocalUserData} from 'storage/localStorage';

const {PENDING_AUTH, PENDING_AUTH_CAPTURE, INVALID, SUCCESS, FAILURE, FAILED} = responseStatus;

export const STEPS = {
    INITIAL: 'INITIAL',
    PENDING: 'PENDING',
    CONFIRM: 'CONFIRM',
    SELECT_OPTIONS: 'SELECT_OPTIONS',
    NEW_CARD: 'NEW_CARD',
    WAITING_FOR_PAYMENT: 'WAITING_FOR_PAYMENT',
    FAILED: 'FAILED',
    OTP_VERIFICATION: 'OTP_VERIFICATION',
};

export const emptyCard = {
    cardNumber: '',
    expiryDate: '',
    cvv: '',
    saveCard: false,
    pin: '',
    isVerve: false,
};

export const PayWithCardContext = createContext({});
export const usePayWithCard = () => useContext(PayWithCardContext);

export const PayWithCardProvider = ({clientWidth, checkoutData = {}, children}) => {
    const dateRef = useRef(null);
    const cvvRef = useRef(null);
    const pinRef = useRef(null);

    const [currentState, setCurrentState] = useState(STEPS.INITIAL);
    const [previousState, setPreviousState] = useState(STEPS.INITIAL);
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [reason, setReason] = useState('');
    const [formErrors, setFormErrors] = useState({});
    const [cardDetails, setCardDetails] = useState({});
    const [url, setURL] = useState('');
    const [verveMessage, setVerveMessage] = useState('');
    const [formValid, setFormValid] = useState({...utils.nextData});
    const [cardInfo, setCardInfo] = useState({...utils.validCardInfo});
    const [card, setCard] = useState(emptyCard);

    const {metadata, API, apiVersion, setCardData, reference, updateCheckoutData} = checkoutData;
    const {customer: customerData, customerEmail, merchant, other_info, inApp} = metadata || {};
    const customer = other_info?.customer ?? customerData;
    const Authorization = merchant?.publicKey;
    const email = customerEmail ?? customer?.email ?? merchant?.email;
    const config = {headers: {Authorization}};
    const cvvLength = cardInfo?.code?.size || 4;
    const encryptKey = process.env.REACT_APP_CARD_ENCRYPT_KEY;

    const updateCurrentState = newState => {
        setLoading(false);
        setErrorMsg('');
        setPreviousState(currentState);
        setCurrentState(newState);
    };

    const updateError = name => {
        setFormErrors(prev => ({
            ...prev,
            [name]: '',
        }));
    };

    const handleError = message => {
        setLoading(false);
        setErrorMsg({msg: message});
    };

    const reset = () => {
        setLoading(false);
        setErrorMsg('');
        setReason();
        setCurrentState(STEPS.NEW_CARD);
    };

    const receiveMessage = event => {
        let {data = {}} = event || {};
        data = typeof data === 'string' ? JSON.parse(data) : data;
        const {status, result, message} = data;
        if (status === SUCCESS) updateCurrentState(STEPS.CONFIRM);
        if (result === FAILURE || (result !== FAILURE && status === FAILED)) {
            updateCurrentState(STEPS.FAILED);
            setReason(message ?? 'Transaction cancelled or not completed');
        }
    };

    const handleChange = ({target: {name, value}}) => {
        let cardData = {};
        if (name === 'cardNumber') {
            cardData = utils.formatCardNumber(value);
            setValue('isVerve', cardData.isVerve);
            setCardInfo({...cardData?.card, isVerve: cardData.isVerve});
            updateCheckoutData({cardNice: cardData?.card?.niceType});
            updateError(name, cardData.error);
            !cardData.isVerve && cardData.next && dateRef?.current?.focus();
        } else if (name === 'pin') {
            if (value.length === 4) {
                cardData = {
                    newValue: value,
                    next: true,
                };
            }
        } else if (name === 'expiryDate') {
            cardData = utils.formatExpirationDate(value);
            updateError(name, cardData.error);
            cardData.next && cvvRef.current?.focus();
        }
        setValue(name, cardData?.newValue);
        setFormValidity(name, !cardData?.next);
    };

    const handleCVV = ({target: {name, value}}) => {
        value = value.replace(/[^0-9]/g, '');
        setValue(name, value);
        setFormValidity(name, !(value.length === cvvLength));
        updateError(name, '');
        value.length === cvvLength && card.isVerve && pinRef.current?.focus();
    };

    const setValue = (name, value) => {
        setCard(prev => ({
            ...prev,
            [name]: value,
        }));
    };

    const setFormValidity = (name, value) => {
        setFormValid(prev => ({
            ...prev,
            [name]: value,
        }));
    };

    const getExpiry = val => {
        const expiry = val.split('/');
        return `${expiry?.[0]}/${expiry?.[1]?.toString()?.substr(-2)}`;
    };

    const getPayload = () => {
        const {
            business,
            business_details,
            first_name,
            last_name,
            firstName,
            lastName,
            name: customerName,
        } = customer || {};

        const flName = `${first_name ?? firstName} ${last_name ?? lastName}`;
        const notACustomer = !first_name || !firstName;

        const cardHolder = notACustomer
            ? merchant?.name
            : business
            ? business_details?.name
            : first_name || firstName
            ? flName
            : customerName;

        const payload = {
            reference,
            pan: card?.cardNumber.replace(/\s/g, ''),
            cvv: card?.cvv,
            // expiry: '03/50',
            expiry: getExpiry(card?.expiryDate),
            deviceSignature: cardHolder,
            cardHolder,
            saveCard: card.saveCard,
        };
        card.isVerve && (payload.pin = card?.pin);
        return payload;
    };

    const handleCardPayment = async () => {
        try {
            setErrorMsg();
            setLoading(true);

            const payload = getPayload();

            const response = await API.post(`${apiVersion}${initiateCard}`, payload, config);

            const {status, data} = response || {};
            const st = data?.status || data?.data?.status;
            const ok = data?.ok || data?.data?.ok || data.success;
            const redirectUrl =
                data?.redirectUrl ||
                data?.data?.redirectUrl ||
                data?.redirect_url ||
                data?.data?.redirect_url;
            const message =
                data?.message || data?.data?.message || 'Error Processing Request. Try again.';

            if ((status === 200 || status === 201) && ok) {
                setLoading(false);
                if (st === PENDING_AUTH_CAPTURE || st === PENDING_AUTH) {
                    if (card?.saveCard && !inApp) {
                        setCardData?.({...card, publicKey: Authorization, email});
                    }
                    if (st == PENDING_AUTH_CAPTURE) {
                        setVerveMessage(data?.message);
                        updateCurrentState(STEPS.OTP_VERIFICATION);
                    } else {
                        updateCurrentState(STEPS.PENDING);
                        setURL(`${redirectUrl}?w=${clientWidth}&h=350`);
                    }
                } else if (st === FAILED || st === INVALID) handleError(message);
            } else handleError(message);
        } catch (error) {
            handleError(getError(error?.response?.data));
        }
    };

    const PayWithTokenizedCard = async token => {
        try {
            setErrorMsg();
            setLoading(true);
            const payload = {email, reference};

            const response = await API.post(
                `${apiVersion}${chargeTokenizedCard(token)}`,
                payload,
                config
            );

            const {status, data} = response || {};

            if ((status === 201 || status === 200) && (data.status || data.success)) {
                setLoading(false);
                updateCurrentState(STEPS.WAITING_FOR_PAYMENT);
            } else handleError(message);
        } catch (error) {
            handleError(getError(error?.response?.data));
        }
    };

    const VerifyVerveOtp = async code => {
        try {
            setErrorMsg();
            setLoading(true);

            const response = await API.put(
                `${apiVersion}${chargeTokenizedCard(reference)}`,
                {code},
                config
            );

            const {status, data} = response || {};

            if ((status === 201 || status === 200) && (data.status || data.success || data.ok)) {
                setLoading(false);
                updateCurrentState(STEPS.WAITING_FOR_PAYMENT);
            } else handleError(message);
        } catch (error) {
            handleError(getError(error?.response?.data));
        }
    };

    const getExistingCard = async () => {
        try {
            setLoading(true);
            setErrorMsg();

            if (inApp) {
                const version = apiVersion ? `${apiVersion}/checkout` : '';
                const response = await API.get(`${version}${getTokenizeCard}`, {
                    params: {email},
                    ...config,
                });

                const {status, data} = response || {};
                if ((status === 201 || status === 200) && (data.status || data.success)) {
                    setCardDetails(data?.data?.TokenizedCards);
                }
            } else {
                const storeKey = `${window.location.origin}${encryptKey}${email}`;
                let savedCards = fetchLocalUserData({storeKey});
                savedCards = Object.values(savedCards)?.filter(
                    v => v.publicKey === Authorization && v.email === email
                );
                setCardDetails(savedCards);
            }
            updateCurrentState(STEPS.NEW_CARD);
        } catch (error) {
            setCardDetails([]);
            updateCurrentState(STEPS.NEW_CARD);
        }
    };

    return (
        <PayWithCardContext.Provider
            value={{
                url,
                reason,
                config,
                verveMessage,
                previousState,
                currentState,
                loading,
                setValue,
                errorMsg,
                setErrorMsg,
                formErrors,
                updateError,
                setFormErrors,
                reset,
                getExistingCard,
                updateCurrentState,
                cardDetails,
                receiveMessage,
                cvvLength,
                cardInfo,
                card,
                formValid,
                handleCardPayment,
                handleChange,
                handleCVV,
                dateRef,
                cvvRef,
                pinRef,
                setCard,
                VerifyVerveOtp,
                PayWithTokenizedCard,
                checkoutData,
            }}
        >
            {children}
        </PayWithCardContext.Provider>
    );
};
