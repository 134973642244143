import Alert from 'utils/Alert/Alert';
import WaitingForPayment from 'components/Checkouts/Checkout/common/WaitingForPayment';
import {MoMoPhoneNumberForm} from './MoMoPhoneNumberForm';
import {STEPS, usePayWithMoMo} from './PayWithMomoContext';

export const PayWithMoMo = () => {
    const {currentState, errorMsg, setErrorMsg} = usePayWithMoMo();

    const render = () => {
        switch (currentState) {
            case STEPS.PHONE_VERIFICATION:
            default:
                return <MoMoPhoneNumberForm />;

            case STEPS.WAITING_FOR_PAYMENT:
                return <WaitingForPayment />;
        }
    };
    return (
        <>
            {errorMsg && <Alert {...errorMsg} onClose={() => setErrorMsg()} />}
            {render()}
        </>
    );
};
