import {PENDING, SUCCESS} from 'components/constants';

export const colors = {
    // primaryColor: "#FF0000",
    // primaryTextColor: "#FFFFFF",
    // secColor: "#00FF00",
    // secTextColor: "#FFFFFF",
    // accentColor: "#FFFF00",
    // accentTextColor: "#000",
};

export const InvoiceHeadings = ['Item Name', 'Unit Cost', 'Quantity', 'Total Cost'];

export const getStatus = text => {
    let status;
    (text === 'draft' || text === 'partially_paid') && (status = PENDING);
    (text === 'issued' || text === 'paid') && (status = SUCCESS);
    return {status, text};
};
