import axios from 'axios';

let CancelToken = axios.CancelToken;
let axiosRequestSignal;

const baseURLs = [
    process.env.REACT_APP_INVOICE_API,
    process.env.REACT_APP_WALLET_BASE_URL,
    process.env.REACT_APP_BANK_ACCOUNT_BASE_URL,
    process.env.REACT_APP_PAYMENT_PRO_BASE_URL,
];

const APIs = [];

baseURLs.forEach(url => {
    let API = axios.create({baseURL: url});

    API.interceptors.request.use(config => {
        axiosRequestSignal = CancelToken.source();
        config.cancelToken = axiosRequestSignal.token;

        const headers = {...(config?.headers || {})};

        config.headers = {
            'x-api-key': process.env.REACT_APP_ENVIRONMENT,
            source: 'checkout',
            ...headers,
        };
        return config;
    });

    API.interceptors.response.use(
        async response => response,
        async error => {
            return Promise.reject(error);
        }
    );

    APIs.push(API);
});

export const requestSignal = axiosRequestSignal;
export const walletAPI = APIs[1];
export const bankAPI = APIs[2];
export const paymentAPI = APIs[3];
export default APIs[1];
