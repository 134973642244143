import {Fragment} from 'react';
import {ReactComponent as BrailleDot} from 'assets/images/brailledot.svg';

const CheckoutMethodSelect = ({
    methods,
    activeMethod,
    setActiveMethod,
    setMethods,
    updateCheckoutData,
}) => {
    const flows = [...methods].slice(0, 2);
    const drops = [...methods].slice(2, methods.length);
    const nonDigitalWallets = drops.filter(drop => drop.group !== 'digital-wallets');
    const digitalWallets = drops.filter(drop => drop.group === 'digital-wallets');

    const handleDropChange = val => {
        updateCheckoutData({cardNice: ''});
        const newMethods = methods?.filter(({name}) => name !== val?.name);
        setMethods([val, ...newMethods]);
        setActiveMethod(val);
    };

    const getDropdownItem = (method = {}) => {
        const {name, Icon} = method;
        return (
            <li key={name} className="dropdown-item" onClick={() => handleDropChange(method)}>
                <Icon /> {name}
            </li>
        );
    };

    return (
        <div className="row checkout__methods__select">
            <div className="col-11">
                <div className="row me-1">
                    {flows?.map(flow => (
                        <div key={flow.name} className="col-6">
                            <div
                                className={`card__method ${
                                    flow?.name === activeMethod?.name ? 'active' : ''
                                }`}
                                onClick={() => handleDropChange(flow)}
                            >
                                {flow?.name} {flow.Icon && <flow.Icon />}
                                <div className="icon" />
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {methods.length > 2 && (
                <div className="col-1 flex__center">
                    <div className="card__method options">
                        <div className="dropdown dropstart">
                            <BrailleDot
                                className="me-0 dropdown-toggle pointer"
                                data-bs-toggle="dropdown"
                            />
                            <ul className="dropdown-menu">
                                {nonDigitalWallets?.map(nonDigitalWallet =>
                                    getDropdownItem(nonDigitalWallet)
                                )}

                                {digitalWallets.length > 0 && (
                                    <Fragment>
                                        <div className="mt-3 item">DIGITAL WALLET</div>
                                        {digitalWallets?.map(digitalWallet =>
                                            getDropdownItem(digitalWallet)
                                        )}
                                    </Fragment>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CheckoutMethodSelect;
