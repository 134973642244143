import {useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';
import {getTransactionStatus} from 'api/routes/wallet';
import CheckoutWrapper from '../common/CheckoutWrapper';
import CheckoutHeader from './CheckoutHeader/CheckoutHeader';
import CheckoutMethodSelect from './CheckoutMethodSelect/CheckoutMethodSelect';
import TransactionStatus from '../common/TransactionStatus';
import CheckoutForm from './CheckoutForm/CheckoutForm';
import {DONE, responseStatus} from 'components/constants';
import {storeLocalUserData} from 'storage/localStorage';
import {isParams} from 'utils/helpers';

const {IDLE, SUCCESS, SUCCESSFUL} = responseStatus;

const CheckoutContent = ({checkoutData = {}}) => {
    const [counter, setCounter] = useState(10);

    const [tranzStatus, setTranzStatus] = useState(IDLE);
    const [timeLeft, setTimeLeft] = useState({});
    const [timeExpired, setTimeExpired] = useState(false);
    const [activeMethod, setActiveMethod] = useState('');
    const [cardData, setCardData] = useState({});

    const {reference} = useParams();

    const pollInterval = useRef();

    const {frame, sendEvent, methods, handleClose, setMethods, updateCheckoutData} = checkoutData;
    const {API, apiVersion, metadata} = checkoutData;

    const {
        returnUrl,
        redirectSuccessUrl,
        showSuccessBtnOnDone,
        customer,
        metadata: subMetaData,
    } = metadata || {};

    const showBtn =
        showSuccessBtnOnDone ??
        subMetaData?.showSuccessBtnOnDone ??
        customer?.metadata?.showSuccessBtnOnDone;

    const encryptKey = process.env.REACT_APP_CARD_ENCRYPT_KEY;
    const startRedirectCounter = tranzStatus === SUCCESS;
    const shouldRedirect = returnUrl || redirectSuccessUrl;
    const successMessage = shouldRedirect
        ? `Redirecting in ${counter}s`
        : 'We have received your payment.';

    useEffect(() => {
        setCardData({});
        setActiveMethod(methods[0]);
    }, [methods]);

    let countDown = 0;

    useEffect(() => {
        handleTimeInterval();

        if (reference) {
            pollInterval.current = setInterval(() => {
                handleTransactionStatus();
            }, 10000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reference]);

    const format = t => (t < 10 ? '0' + t : t);

    const handleTimeInterval = () => {
        setTimeExpired(false);
        countDown = Date.now() + 1800000;
        const timeInterval = setInterval(() => {
            const distance = countDown - Date.now();

            let minutes = format(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)));
            let seconds = format(Math.floor((distance % (1000 * 60)) / 1000));

            if (distance < 0) {
                setTimeExpired(true);
                clearInterval(timeInterval);
            }

            setTimeLeft({minutes, seconds});
        }, 1000);
    };

    const handleClick = () => {
        if (returnUrl) {
            const isParam = isParams(returnUrl);
            const url = `${returnUrl}${
                isParam ? '&' : '?'
            }reference=${reference}&status=Successful`;
            window.location?.replace(url);
        } else if (redirectSuccessUrl) {
            const isParam = isParams(redirectSuccessUrl);
            const url = `${redirectSuccessUrl}${isParam ? '&' : '?'}reference=${reference}`;
            window.location?.replace(url);
        }
        sendEvent({name: DONE, paymentMethod: activeMethod?.name, reference});
    };

    useEffect(() => {
        const interval = setInterval(() => {
            if (startRedirectCounter && shouldRedirect) {
                if (counter > 1) {
                    setCounter(prev => prev - 1);
                } else {
                    setCounter(0);
                    handleClick?.();
                }
            }
        }, 1000);
        return () => {
            clearInterval(interval);
        };
    }, [counter, startRedirectCounter]);

    const handleTransactionStatus = async () => {
        try {
            const response = await API.get(getTransactionStatus(reference, apiVersion));

            const {status, data} = response || {};

            const st = (data.data.status || data?.data?.data?.status) === SUCCESSFUL;
            const suc = data?.status || data?.success;

            if (status === 200 && suc && st) {
                clearInterval(pollInterval.current);
                setTranzStatus(SUCCESS);
            }
        } catch (error) {}
    };

    useEffect(() => {
        if (tranzStatus === SUCCESS) {
            const {cvv, cardNumber, saveCard, email} = cardData || {};
            if (saveCard && !metadata?.inApp) {
                const storeKey = `${window.location.origin}${encryptKey}${email}`;
                const cardKey = `${cardNumber.replaceAll(' ', '')}${cvv}`;
                storeLocalUserData({userData: {[cardKey]: cardData}, storeKey});
            }
        }
    }, [tranzStatus]);

    const renderByStatus = () => {
        switch (tranzStatus) {
            case IDLE:
            default:
                return (
                    <>
                        <CheckoutMethodSelect
                            activeMethod={activeMethod}
                            methods={methods}
                            setActiveMethod={setActiveMethod}
                            setMethods={setMethods}
                            updateCheckoutData={updateCheckoutData}
                        />
                        <CheckoutForm
                            checkoutData={{
                                ...checkoutData,
                                timeLeft,
                                timeExpired,
                                activeMethod,
                                setTranzStatus,
                                setCardData,
                            }}
                        />
                    </>
                );
            case SUCCESS:
                return (
                    <TransactionStatus
                        title="Transaction Successful"
                        message={successMessage}
                        handleClick={handleClick}
                        btnName={shouldRedirect ? 'Redirect' : 'Done'}
                        showBtn={showBtn}
                    />
                );
        }
    };

    return (
        <CheckoutWrapper isFrame={frame} reference={reference} sendEvent={handleClose}>
            <CheckoutHeader checkoutData={{...checkoutData, activeMethod}} />

            <div className="p-5">{renderByStatus()}</div>
        </CheckoutWrapper>
    );
};

export default CheckoutContent;
