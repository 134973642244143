import React from 'react';
import {ReactComponent as Error} from 'assets/images/error.svg';

const FailedState = ({errorMsg, refetch}) => {
    return (
        <div className="d-flex justify-content-center p-5">
            <div className="text-center">
                {/* <img src="/assets/img/icons/error.svg" alt="" width="50px" /> */}
                <Error width={50} />

                <h4 className="text-danger fw-bold">{errorMsg}</h4>

                <br />

                {refetch && (
                    <button
                        className="btn btn--sm btn--primary px-5 mx-auto fw-bold"
                        onClick={refetch}
                    >
                        Retry
                    </button>
                )}
            </div>
        </div>
    );
};

export default FailedState;
