import * as React from 'react';
import {ExistingCard} from './ExistingCard';
import {ReactComponent as AddIcon} from 'assets/images/add-circle.svg';
import {emptyCard, STEPS, usePayWithCard} from './PayWithCardContext';
import CustomInputBox from 'utils/CustomInput/CustomInputBox';
import CustomSpinner from 'utils/Spinner/Spinner';

export const ExistingCardsList = () => {
    const [selected, setSelected] = React.useState({});
    const [cvv, setCVV] = React.useState('');
    const [error, setError] = React.useState();
    const {loading, cardDetails, updateCurrentState, handleCardPayment, setCard} = usePayWithCard();

    const handleClick = () => {
        if (selected.cvv) {
            cvv === selected.cvv
                ? handleCardPayment()
                : setError(`Invalid ${selected.cvvData.name}`);
        }
    };

    const disabled = (selected.cvv ? cvv.length < 3 || cvv.length > 4 : '') || !!error || loading;

    return (
        <>
            <div className="text-center pt-3">
                <span className="g-bold text--gray">Choose card to continue</span>
            </div>

            {loading && <div className="my-4 text-center w-100 fw-bold">Please Wait ...</div>}

            <div className="row align-items-stretch pt-4 account__container">
                <div className="col-6">
                    <div
                        className={`account__list add__new align-items-center h-100 ${
                            loading ? 'disabled' : ''
                        }`}
                        onClick={() => {
                            if (!loading) {
                                setCard(emptyCard);
                                updateCurrentState(STEPS.NEW_CARD);
                            }
                        }}
                    >
                        <AddIcon className="mx-3" /> <h5 className="m-0">Use a different Card</h5>
                    </div>
                </div>
                {cardDetails?.map((cardData, i) => (
                    <ExistingCard
                        key={i}
                        cardData={cardData}
                        setSelected={setSelected}
                        active={
                            selected.cvv
                                ? selected.cvv === cardData.cvv &&
                                  selected.cardNumber === cardData.cardNumber &&
                                  selected.expiryDate === cardData.expiryDate
                                : (selected.authToken = cardData.authToken)
                        }
                    />
                ))}
            </div>

            {selected.cvv && (
                <div className="row align-items-end">
                    <div className="col-sm-6">
                        <CustomInputBox
                            cls="mb-0"
                            name="cvv"
                            label={`Enter ${selected?.cvvData?.name}`}
                            value={cvv}
                            error={error}
                            placeholder={`Enter the selected card ${selected?.cvvData?.name}`}
                            maxLength={selected?.cvvData?.size || 4}
                            onChange={({target}) => {
                                setError();
                                setCVV(target?.value.replace(/[^0-9]/g, ''));
                            }}
                            pattern="\d{3,4}"
                        />
                    </div>
                    <div className="col-sm-6">
                        <button
                            className={`btn btn--lg btn--primary g-bold ${error ? 'mb-19' : ''}`}
                            type="button"
                            disabled={disabled}
                            onClick={handleClick}
                        >
                            {loading ? <CustomSpinner /> : 'Submit'}
                        </button>
                    </div>
                </div>
            )}
        </>
    );
};
