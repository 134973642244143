import PayWithBankTransferForm from './PayWithBankTransferForm/PayWithBankTransferForm';
import CountDownPaymentWrapper from 'components/Checkouts/Checkout/common/CountDownPaymentWrapper';

const PayWithBankTransfer = ({checkoutData, refetch}) => {
    return (
        <CountDownPaymentWrapper
            refetch={refetch}
            checkoutData={checkoutData}
            Component={PayWithBankTransferForm}
        />
    );
};

export default PayWithBankTransfer;
