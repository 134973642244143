import {useEffect, useState} from 'react';
import CheckoutWrapper from 'components/Checkouts/Checkout/common/CheckoutWrapper';
import CheckoutHeader from 'components/Checkouts/Checkout/CheckoutContent/CheckoutHeader/CheckoutHeader';
import CustomSelect from 'utils/Selects/CustomSelect/CustomSelect';
import CustomInputBox from 'utils/CustomInput/CustomInputBox';
import {formValidator} from 'utils/FormValidator/FormValidator';
import CustomSpinner from 'utils/Spinner/Spinner';
import {appendPlusToNumber, getCurrencySymbol} from 'utils/helpers';
import Alert from 'utils/Alert/Alert';
import CustomPhoneInput from 'utils/CustomInput/CustomPhoneInput';
import {useApps} from 'components/App/AppContext';
import Footer from 'components/Common/Footer';
import {PAYMENT_LINK, QR_CODE, STATIC} from 'components/constants';

export const PaymentForm = ({paymentData = {}, initiateQuery, errorMsg, type = PAYMENT_LINK}) => {
    const {colors: appColors, metadata: appMetaData} = useApps();
    const {reference, amount: amt, currency, metadata: paymentMetadata} = paymentData;
    const {usage, currencies} = paymentData;

    const logo = paymentData?.logo || appMetaData?.company_logo;
    const {isLoading, mutate} = initiateQuery ?? {};
    const isStatic = usage === STATIC && type === QR_CODE;

    const colors = {
        primaryColor: paymentData?.primaryColor || appColors?.primaryColor,
        secColor: paymentData?.secColor || appColors?.secColor,
    };

    const metadata = {...paymentMetadata, type, colors};
    let initialFormValues = {
        first_name: '',
        last_name: '',
        phone_number: '',
        email: '',
        amount: amt,
    };

    if (type === PAYMENT_LINK) {
        initialFormValues = {...initialFormValues, metadata};
    }

    const [fieldErrors, setFieldErrors] = useState({});
    const [formValues, setFormValues] = useState(initialFormValues);

    const currencyCode =
        isStatic && !formValues.currency
            ? ''
            : getCurrencySymbol(formValues.currency?.value ?? currency);

    useEffect(() => {
        const newMeta = type === PAYMENT_LINK ? {metadata} : {};
        setFormValues(prev => ({...prev, ...newMeta, amount: amt ?? prev.amt}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type, paymentData]);

    const handleChange = ({target: {name, value}}) => {
        handleInput(name, value);
    };

    const handleNumber = ({target: {name, value}}) => {
        value = value.replace(/[^0-9]/g, '');
        handleInput(name, value);
    };

    const handleInput = (name, value) => {
        setFormValues(prev => ({
            ...prev,
            [name]: value,
        }));
        setFieldErrors(prev => ({
            ...prev,
            [name]: '',
        }));
    };

    const handleSubmit = e => {
        e.preventDefault();
        let payload = {...formValues};
        isStatic && (payload.currency = formValues.currency?.value);
        const {formErrors, isValid} = formValidator(payload);

        isValid
            ? mutate({
                  ...payload,
                  amount: +formValues?.amount,
                  phone_number: appendPlusToNumber(formValues?.phone_number),
                  currency: currency,
              })
            : setFieldErrors(formErrors);
    };

    return (
        <CheckoutWrapper reference={reference}>
            <CheckoutHeader link checkoutData={paymentData} />

            {errorMsg && <Alert msg={errorMsg} cls="px-5 mt-4" onClose={() => setErrorMsg()} />}

            <form onSubmit={handleSubmit} className="px-5 pb-5 row">
                <div className="col-12  bg-light fw-bold text--primary rounded my-4 text-center  p-3">
                    {paymentData?.description}
                </div>
                {isStatic && (
                    <div className="col-sm-6">
                        <CustomSelect
                            name="currency"
                            label="Select Currency"
                            value={formValues?.currency}
                            error={fieldErrors?.currency ? true : false}
                            onChange={value => handleInput('currency', value)}
                            options={currencies?.map(value => ({label: value, value}))}
                            required
                        />
                    </div>
                )}
                <div className="col-sm-6">
                    <CustomInputBox
                        required
                        type="tel"
                        name="amount"
                        label={`Amount ${currencyCode ? `( ${currencyCode} )` : ''} `}
                        error={fieldErrors?.amount ? true : false}
                        value={formValues?.amount}
                        placeholder="Enter Amount"
                        onChange={amt ? '' : handleNumber}
                        disabled={amt}
                    />
                </div>

                <div className="col-sm-6">
                    <CustomInputBox
                        required
                        name="first_name"
                        label="First Name"
                        error={fieldErrors?.first_name ? true : false}
                        value={formValues?.first_name}
                        placeholder="Enter First Name"
                        onChange={handleChange}
                    />
                </div>

                <div className="col-sm-6">
                    <CustomInputBox
                        required
                        name="last_name"
                        label="Last Name"
                        error={fieldErrors?.last_name ? true : false}
                        value={formValues?.last_name}
                        placeholder="Enter Last Name"
                        onChange={handleChange}
                    />
                </div>

                <div className="col-sm-6">
                    <CustomPhoneInput
                        required
                        label="Phone Number"
                        name="phone_number"
                        placeholder="Phone Number"
                        onChange={e => handleInput('phone_number', e)}
                        value={formValues?.phone_number}
                        error={fieldErrors?.phone_number ? true : false}
                    />
                </div>

                <div className={`${isStatic ? 'col-sm-6' : 'col-12'}`}>
                    <CustomInputBox
                        required
                        type="email"
                        name="email"
                        label="Email"
                        error={fieldErrors?.email ? true : false}
                        value={formValues?.email}
                        placeholder="Enter Email"
                        onChange={handleChange}
                    />
                </div>

                <div className="col-12 pt-4">
                    <button
                        className="btn btn--lg btn--primary g-bold"
                        type="submit"
                        disabled={isLoading || !(formValues.amount > 0)}
                    >
                        {isLoading ? <CustomSpinner /> : 'Continue'}
                    </button>
                </div>
            </form>
            <Footer logo={logo} poweredBy="Payment Link" />
        </CheckoutWrapper>
    );
};
