import styled from 'styled-components';

export const InvoiceLayout = styled.section`
    &.bg--primary,
    .bg--primary,
    .btn--primary,
    .btn--primary:hover {
        background: ${({colors}) => colors?.primaryColor};
    }
`;
