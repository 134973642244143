import React, {useEffect} from 'react';
import {requestSignal} from 'api';
import CustomInputBox from 'utils/CustomInput/CustomInputBox';
import CustomSelect from 'utils/Selects/CustomSelect/CustomSelect';
import {formValidator} from 'utils/FormValidator/FormValidator';
import CustomSpinner from 'utils/Spinner/Spinner';
import {responseStatus} from 'components/constants';
import {STEPS, usePayWithBankAccount} from '../PayWithBankAccountContext';
import {titleCase} from 'utils/helpers';

const {IDLE, LOADING, SUCCESS} = responseStatus;

export const AddAccountForm = () => {
    const {
        formValues,
        loading,
        verifyAccountStatus,
        resolveAccount,
        formErrors,
        updateData,
        bankDetails,
        initiateCharge,
        updateCurrentState,
    } = usePayWithBankAccount();

    const {accountNumber, narration, customerName, bank} = formValues;

    const disabled =
        !customerName ||
        accountNumber?.length !== 10 ||
        verifyAccountStatus === IDLE ||
        verifyAccountStatus === LOADING ||
        loading;

    useEffect(() => {
        if (accountNumber?.length === 10 && bank) resolveAccount();
        () => requestSignal.cancel();
    }, [bank, accountNumber, resolveAccount]);

    const handleSubmit = e => {
        e.preventDefault();
        const {formErrors, isValid} = formValidator({
            accountNumber,
            customerName,
            bank: bank.bankName,
        });
        isValid ? initiateCharge(bank) : setFieldErrors(formErrors);
    };

    const renderByStatus = () => {
        switch (verifyAccountStatus) {
            case LOADING:
                return (
                    <div className="flex__center">
                        Validating Account... <CustomSpinner cls="text-secondary ms-3" />
                    </div>
                );

            case SUCCESS:
                return (
                    <p className="text--gray m-0">
                        Account Name:
                        <span className="text--primary ps-3">{formValues?.customerName}</span>
                    </p>
                );
            default:
                return '';
        }
    };

    return (
        <>
            <div className="text-center pt-3">
                <span className="g-bold text--gray">Enter bank information to receive OTP</span>
            </div>

            <form className="row pt-4" onSubmit={handleSubmit}>
                <div className="col-sm-6">
                    <CustomSelect
                        name="bank"
                        label="Select Bank"
                        value={bank}
                        error={formErrors?.bank}
                        onChange={e => updateData({bank: e})}
                        required
                        options={bankDetails?.banks}
                        getOptionLabel={option => titleCase(option?.bankName)}
                    />
                </div>

                <div className="col-sm-6">
                    <CustomInputBox
                        required
                        type="tel"
                        name="accountNumber"
                        label="Account Number"
                        maxLength={10}
                        error={formErrors?.accountNumber}
                        value={accountNumber}
                        placeholder="Enter Account Number"
                        onChange={({target}) =>
                            updateData({accountNumber: target?.value.replace(/[^0-9]/g, '')})
                        }
                    />
                </div>

                <div className="col-12">
                    <CustomInputBox
                        name="narration"
                        label="Narration (Optional)"
                        error={formErrors?.narration}
                        value={narration}
                        placeholder="Narration (Optional)"
                        onChange={({target}) => updateData({narration: target?.value})}
                    />
                </div>

                {verifyAccountStatus !== IDLE && (
                    <div className="col-12 text-center g-bold bg-light rounded w-100 p-3">
                        {renderByStatus()}
                    </div>
                )}

                <div className="col-12 flex__between pt-4">
                    <button
                        className="btn btn--lg btn--primary--bordered g-bold"
                        type="button"
                        onClick={() =>
                            updateCurrentState(
                                bankDetails?.accounts.length
                                    ? STEPS.SELECT_OPTIONS
                                    : STEPS.PHONE_VERIFICATION
                            )
                        }
                    >
                        Back
                    </button>
                    <button
                        className="btn btn--lg btn--primary g-bold"
                        type="submit"
                        disabled={disabled}
                    >
                        {loading ? <CustomSpinner /> : 'Continue'}
                    </button>
                </div>
            </form>
        </>
    );
};

export default AddAccountForm;
