import {useState, useContext} from 'react';
import Header from 'components/Common/Header';
import Footer from 'components/Common/Footer';
import CustomInputBox from 'utils/CustomInput/CustomInputBox';
import {CustomCheckBox} from 'utils/CustomInput/CustomCheckBox';
import WalletIcon from 'assets/images/wallet-icon.svg';
import SubscriptionSummary from './SubscriptionSummary';
import AppContext from 'components/App/AppContext';
import SubscriptionOTPModal from '../SubscriptionModals/SubscriptionOTPModal';
import SubscriptionFundWalletModal from '../SubscriptionModals/SubscriptionFundWalletModal';
import CustomSpinner from 'utils/Spinner/Spinner';
import {getError} from 'utils/helpers';
import Alert from 'utils/Alert/Alert';
import {getCurrencySymbol, toCurrency} from 'utils/helpers';
import {initializeCheckout, sendOTP} from 'api/routes/wallet';

const SubscriptionWalletContent = ({paymentData}) => {
    const [errorMsg, setErrorMsg] = useState();
    const [loading, setLoading] = useState(false);
    const [otpLoading, setOtpLoading] = useState(false);

    const [automate, setAutomate] = useState(true);

    const [isOpen, setOpen] = useState(false);
    const toggleModal = () => setOpen(prev => !prev);

    const [isFundWalletModalOpen, setFundWalletModalOpen] = useState(false);
    const toggleFundWalletModal = () => setFundWalletModalOpen(prev => !prev);

    const curr = getCurrencySymbol(paymentData?.metadata?.plan?.currency);
    const total = `${curr} ${toCurrency(paymentData?.amountAfter)}`;

    const {API, apiVersion, refetch} = paymentData;
    const {metadata: appMetadata, setCheckoutData} = useContext(AppContext);
    const logo = paymentData?.logo || appMetadata?.company_logo;

    const setError = message => {
        setLoading(false);
        setErrorMsg(message);
    };

    const handleInitializeCheckout = async amount => {
        try {
            setLoading(true);
            setErrorMsg();
            const response = await API.post(
                `${apiVersion}${initializeCheckout}`,
                {
                    walletId: paymentData?.option?.walletRef,
                    currency: paymentData?.metadata?.plan?.currency,
                    amount: +amount,
                    metadata: {inApp: true},
                },
                {headers: {Authorization: paymentData?.publicKey}}
            );

            const {status, data} = response || {};

            if (status === 200 && (data.status || data.success)) {
                setLoading(false);
                setCheckoutData({
                    url: data?.data?.authorization_url,
                    refetch,
                });
            } else {
                setError(data?.message);
                setLoading(false);
            }
        } catch (error) {
            setError(getError(error.response?.data || error.response));
            setLoading(false);
        }
    };

    const handleSendOTP = async () => {
        try {
            setOtpLoading(true);
            setErrorMsg();
            const response = await API.post(
                `${apiVersion}${sendOTP}`,
                {
                    reference: paymentData?.reference,
                },
                {headers: {Authorization: paymentData?.publicKey}}
            );

            const {status, data} = response || {};

            if (status === 200 && (data.status || data.success)) {
                setOtpLoading(false);
                toggleModal();
            } else {
                setError(data?.message);
                setOtpLoading(false);
            }
        } catch (error) {
            setError(getError(error.response?.data || error.response));
            setOtpLoading(false);
        }
    };

    return (
        <>
            <Header
                logo={logo}
                twitterLink={paymentData?.twitter}
                instagramLink={paymentData?.instagram}
                facebookLink={paymentData?.facebook}
            />
            <div className="subscription-content">
                <SubscriptionSummary paymentData={paymentData} />

                <div className="right-content">
                    {errorMsg && (
                        <Alert msg={errorMsg} cls="px-5 mt-1 w-100" onClose={() => setErrorMsg()} />
                    )}
                    <h1 className="head-1 mb-2">Wallet Details</h1>
                    <div className="col-12">
                        <CustomInputBox
                            label="Email Address"
                            name="email"
                            placeholder="Email Address"
                            value={paymentData?.metadata?.customer?.email}
                            onChange={() => {}}
                            disabled
                        />
                    </div>
                    <div className="col-12">
                        <div className="wallet-balance">
                            <div className="available-balance">
                                <img src={WalletIcon} alt="" />
                                <div>
                                    <p>Available Balance ({paymentData?.currency ?? 'NGN'})</p>
                                    <span>{toCurrency(paymentData?.walletBalance)}</span>
                                </div>
                            </div>
                            <div>
                                <button
                                    className="btn btn--lg"
                                    type="button"
                                    onClick={toggleFundWalletModal}
                                >
                                    {loading ? <CustomSpinner /> : `Fund Wallet`}
                                </button>
                            </div>
                        </div>
                    </div>

                    {paymentData?.subscriptionType === 'recurring' && (
                        <div className="col-12 mt-3">
                            <CustomCheckBox
                                cls="mb-3"
                                label="Automated  for next billing date"
                                name="saveCard"
                                checked={automate}
                                onClick={() => setAutomate(prev => !prev)}
                            />
                        </div>
                    )}

                    <div className="col-12 pt-3 px-0">
                        <button
                            className="btn btn--lg btn--secondary g-bold"
                            type="button"
                            onClick={handleSendOTP}
                        >
                            {otpLoading ? <CustomSpinner /> : ` Pay Amount - ${total}`}
                        </button>

                        {isOpen && (
                            <SubscriptionOTPModal
                                isOpen={isOpen}
                                toggle={toggleModal}
                                automate={automate}
                                paymentData={paymentData}
                            />
                        )}

                        {isFundWalletModalOpen && (
                            <SubscriptionFundWalletModal
                                toggle={toggleFundWalletModal}
                                handleInitializeCheckout={handleInitializeCheckout}
                            />
                        )}
                    </div>
                </div>
            </div>
            <Footer logo={logo} poweredBy="Subscriptions" />
        </>
    );
};

export default SubscriptionWalletContent;
