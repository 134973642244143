import {useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {getPaymentLink} from 'api/routes/wallet';
import BigSpinner from 'utils/Spinner/BigSpinner';
import {getError, getVersion} from 'utils/helpers';
import InitializeError from 'components/Checkouts/Checkout/common/InitializeError';
import {EXPIRED, responseStatus} from 'components/constants';
import {CheckoutLayout} from 'components/Checkouts/CheckoutSC';
import AppContext from 'components/App/AppContext';
import {AppMeta} from 'components/App/AppMeta';
import {PaymentLinkForm} from './PaymentLinkForm';

const {LOADING, FAILED, IDLE, SUCCESS} = responseStatus;

const PaymentLink = ({location}) => {
    const {colors} = useContext(AppContext);

    const [status, setStatus] = useState(IDLE);
    const [errorMsg, setErrorMsg] = useState();
    const [payment, setPayment] = useState({});

    const showTransparent = status === LOADING || errorMsg;
    const pryColor = payment?.primaryColor || colors?.primaryColor;
    const secColor = payment?.secondaryColor || colors?.secondaryColor;

    const {reference} = useParams();
    const {API, apiVersion} = getVersion(location);

    useEffect(() => {
        getPaymentLinkData();
        // eslint-disable-next-line
    }, []);

    const getPaymentLinkData = async () => {
        try {
            setStatus(LOADING);

            const response = await API.get(`${apiVersion}${getPaymentLink(reference)}`);

            const {status, data} = response || {};

            if (status === 200 && (data.status || data.success)) {
                const linkStatus = data?.data?.status;
                if (linkStatus === EXPIRED.toLowerCase()) {
                    setError('Payment link has expired');
                } else {
                    setStatus(SUCCESS);
                    setPayment(data.data);
                }
            } else setError(data?.message);
        } catch (error) {
            setError(getError(error.response?.data || error.response));
        }
    };

    const setError = message => {
        setStatus(FAILED);
        setErrorMsg(message);
    };

    const renderByStatus = () => {
        switch (status) {
            case LOADING:
                return (
                    <div className="loader">
                        <BigSpinner />
                    </div>
                );

            case FAILED:
                return <InitializeError title={' '} errorMsg={errorMsg} retry={() => console()} />;

            case SUCCESS:
                return <PaymentLinkForm paymentData={{...payment, reference, API, apiVersion}} />;
            default:
                return '';
        }
    };
    return (
        <>
            <AppMeta name={payment?.metadata?.merchantName} title="Payment Link" />

            <CheckoutLayout
                colors={{
                    primaryColor: showTransparent ? 'transparent' : pryColor,
                    secColor: showTransparent ? 'transparent' : secColor,
                }}
                className="checkout"
            >
                <div className="checkout__container">
                    <div className="checkout__main">{renderByStatus()}</div>
                </div>
            </CheckoutLayout>
        </>
    );
};

export default PaymentLink;
