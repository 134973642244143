import React, {useState} from 'react';
import OTPInput from 'utils/OTPInput/OTPInput';
import CustomSpinner from 'utils/Spinner/Spinner';

const OTPVerification = ({btnName = 'Submit', loading, onCancel, handleSubmit, message}) => {
    const [otp, setOtp] = useState();

    return (
        <div>
            <div className="text-center py-3 my-4">
                <pre className="g-bold text--gray">
                    {message?.length ? message : `Enter the OTP sent to your phone to continue`}
                </pre>
            </div>

            <div className="flex__center mt-4">
                <OTPInput name="OTPInput" value={otp} onChange={value => setOtp(value)} />
            </div>

            <div className="flex__between mt-5">
                {onCancel && (
                    <button className="btn btn--lg btn--primary--bordered me-2" onClick={onCancel}>
                        Back
                    </button>
                )}
                <button
                    className="btn btn--lg btn--primary ms-2"
                    disabled={!otp || otp?.length < 6 || loading}
                    onClick={() => handleSubmit(otp)}
                >
                    {loading ? <CustomSpinner /> : btnName}
                </button>
            </div>
        </div>
    );
};

export default OTPVerification;
