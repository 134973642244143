import * as React from 'react';
import {ReactComponent as SuccessIcon} from 'assets/images/success.svg';
import {ReactComponent as Danger} from 'assets/images/danger.svg';
import StatusMessage from './StatusMessage';
import {pageInIframe} from 'utils/helpers';

const TransactionStatus = ({
    cls,
    isError,
    title,
    message,
    btnName,
    handleClick,
    showBtn = true,
}) => {
    const [isIframe, setIframe] = React.useState(false);

    React.useEffect(() => {
        if (typeof window !== 'undefined') {
            setIframe(pageInIframe());
        }
    }, []);

    return (
        <div className={`g-bold text-center ${cls}`}>
            {isError ? <Danger width={30} height={30} /> : <SuccessIcon />}

            <StatusMessage
                title={title}
                message={message}
                btnName={isIframe || showBtn ? btnName : ''}
                handleClick={handleClick}
            />
        </div>
    );
};

export default TransactionStatus;
