import React from 'react';
import {Helmet} from 'react-helmet';
import {useApps} from './AppContext';

export const AppMeta = ({linkFavicon, title, name = ''}) => {
    const {favicon} = useApps();
    const defaultFavicon = favicon ?? linkFavicon;

    return (
        <Helmet>
            <meta charSet="utf-8" />
            {defaultFavicon && <link rel="icon" href={defaultFavicon} />}
            <title>
                {name} {title ?? 'Payment Gateway'}
            </title>
        </Helmet>
    );
};
