import React from 'react';
import {usePayWithCard} from './PayWithCardContext';
import {CARD_IMAGES, formatCardNumber} from 'components/Checkouts/Checkout/extra';
import CARD_NULL from 'assets/images/card/credit-card.svg';
import {titleCase} from 'utils/helpers';

export const ExistingCard = ({active, cardData = {}, loading, setSelected}) => {
    const {cvv, cardNumber, expiryDate, card, authToken} = cardData || {};
    const cardLast4 = cardNumber?.substring(cardNumber.length - 4, cardNumber.length);
    const cardInfo = formatCardNumber(cardNumber?.replaceAll(' ', ''));
    const cardImage = cardInfo?.card?.type
        ? CARD_IMAGES?.[cardInfo?.card?.type]
        : card
        ? CARD_IMAGES?.[card?.scheme]
        : CARD_NULL;
    const card_number = `**** **** **** ${card ? card?.last4 : cardLast4}`;

    const {setCard, PayWithTokenizedCard, checkoutData} = usePayWithCard();
    const expDate = expiryDate ?? card?.expiry;

    const handleClick = () => {
        const cardNice = card?.scheme ? titleCase(card?.scheme) : '';
        if (card) {
            checkoutData?.updateCheckoutData({cardNice});
            PayWithTokenizedCard(authToken);
        } else {
            setSelected({...cardData, cvvData: cardInfo?.card?.code});
            setCard({cardNumber, expiryDate: expDate, cvv});
        }
    };

    return (
        <div className="col-6">
            <div
                className={`account__list ${loading ? 'disabled' : ''} ${active ? 'active' : ''}`}
                onClick={handleClick}
            >
                <div className="bullet" />
                <div className="w-100">
                    <p className="flex__between">
                        <span>{card_number}</span>
                        <img
                            src={cardImage}
                            alt={titleCase(cardInfo?.card?.type ?? card?.scheme)}
                        />
                    </p>
                    <small>Exp: {expDate ?? '--/----'}</small>
                </div>
            </div>
        </div>
    );
};
