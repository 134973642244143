import facebook from 'assets/images/facebook.svg';
import instagram from 'assets/images/Instagram.svg';
import twitter from 'assets/images/twitter.svg';
import BlusaltLogo from 'assets/images/logo-blue.svg';

const Header = ({logo, twitterLink, instagramLink, facebookLink}) => {
    const updateLink = link => {
        if (!link) return '';
        if (link?.startsWith('http')) return link;
        return 'https://' + link;
    };

    return (
        <div className="header flex__between">
            <img
                src={logo ? logo : BlusaltLogo}
                alt={''}
                className="logo img-fluid"
                style={{height: '39px', maxWidth: '165px', objectFit: 'contain'}}
            />
            <div className="flex__center gap-3">
                {(twitterLink || instagramLink || facebookLink) && (
                    <p className="contact m-0 me-4 d-none d-lg-block">Contact us</p>
                )}
                {twitterLink && (
                    <a href={updateLink(twitterLink)} target="__blank">
                        <img src={twitter} width="" alt="" />
                    </a>
                )}
                {instagramLink && (
                    <a href={updateLink(instagramLink)} target="__blank">
                        <img src={instagram} width="" alt="" />
                    </a>
                )}
                {facebookLink && (
                    <a href={updateLink(facebookLink)} target="__blank">
                        <img src={facebook} width="" alt="" />
                    </a>
                )}
            </div>
        </div>
    );
};

export default Header;
