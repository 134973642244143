import {useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import Layout from 'components/Common/Layout';
import {getSubscription} from 'api/routes/wallet';
import BigSpinner from 'utils/Spinner/BigSpinner';
import {responseStatus} from 'components/constants';
import {getError, getVersion} from 'utils/helpers';
import SubcriptionCardContent from './SubscriptionContent/SubcriptionCardContent';
import SubscriptionWalletContent from './SubscriptionContent/SubscriptionWalletContent';
import FailedState from 'utils/ResponseState/FailedState';
import AppContext from 'components/App/AppContext';
import {AppMeta} from 'components/App/AppMeta';

const {LOADING, FAILED, IDLE, SUCCESS} = responseStatus;

const Subscription = ({location}) => {
    const {colors} = useContext(AppContext);

    const [status, setStatus] = useState(IDLE);
    const [errorMsg, setErrorMsg] = useState();
    const [payment, setPayment] = useState({});

    const showTransparent = status === LOADING || errorMsg;
    const pryColor = payment?.primaryColor || colors?.primaryColor;
    const secColor = payment?.secondaryColor || colors?.secondaryColor;

    const {reference} = useParams();
    const {API, apiVersion, isPro} = getVersion(location);

    useEffect(() => {
        getSubscriptionData();
        // eslint-disable-next-line
    }, []);

    const getSubscriptionData = async () => {
        try {
            setStatus(LOADING);

            const response = await API.get(`${apiVersion}${getSubscription(reference)}`);

            const {status, data} = response || {};

            if (status === 200 && (data.status || data.success)) {
                setStatus(SUCCESS);
                setPayment(data.data);
            } else setError(data?.message);
        } catch (error) {
            setError(getError(error.response?.data || error.response));
        }
    };

    const setError = message => {
        setStatus(FAILED);
        setErrorMsg(message);
    };

    const renderByStatus = () => {
        switch (status) {
            case LOADING:
                return (
                    <div className="mt-5 pt-5 flex__center  invoice__content">
                        <BigSpinner />;
                    </div>
                );

            case FAILED:
                return (
                    <div className="mt-5 pt-5 flex__center  invoice__content">
                        <FailedState errorMsg={errorMsg} refetch={getSubscriptionData} />
                    </div>
                );

            case SUCCESS:
                return payment?.option?.walletRef ? (
                    <SubscriptionWalletContent
                        paymentData={{
                            ...payment,
                            reference,
                            API,
                            apiVersion,
                            isPro,
                            refetch: getSubscriptionData,
                        }}
                    />
                ) : (
                    <SubcriptionCardContent
                        paymentData={{
                            ...payment,
                            reference,
                            API,
                            apiVersion,
                            isPro,
                            refetch: getSubscriptionData,
                        }}
                    />
                );
            default:
                return '';
        }
    };

    return (
        <>
            <AppMeta title="Subscription System" />
            <Layout
                colors={{
                    primaryColor: showTransparent ? 'transparent' : pryColor,
                    secColor: showTransparent ? 'transparent' : secColor,
                }}
            >
                {renderByStatus()}
            </Layout>
        </>
    );
};

export default Subscription;
