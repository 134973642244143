import React, {useState} from 'react';
import WaitingForPayment from './WaitingForPayment';
import TransactionStatus from './TransactionStatus';
import {CLOSE, CONFIRM, INITIAL} from 'components/constants';
import {useVirtualAccount} from 'hooks';
import BigSpinner from 'utils/Spinner/BigSpinner';

const CountDownPaymentWrapper = ({checkoutData, Component, setBank}) => {
    const [currentStep, setCurrentStep] = useState(INITIAL);

    const {timeExpired, frame, sendEvent, reference, metadata, activeMethod} = checkoutData || {};

    const Authorization = metadata?.merchant?.publicKey;
    const config = {headers: {Authorization}};
    const {isLoading, isError, data} = useVirtualAccount({reference, config});

    const render = () => {
        switch (currentStep) {
            case INITIAL:
            default:
                return (
                    <Component
                        checkoutData={{...checkoutData, virtualAccount: data}}
                        setCurrentStep={setCurrentStep}
                        setBank={setBank}
                    />
                );

            case CONFIRM:
                return (
                    <WaitingForPayment
                        setCurrentStep={setCurrentStep}
                        timeLeft={checkoutData?.timeLeft}
                    />
                );
        }
    };

    if (isLoading)
        return (
            <div className="flex__center py-5">
                <BigSpinner />
            </div>
        );
    else if (isError)
        return (
            <div className="pt-5">
                <TransactionStatus
                    isError
                    title="Payment method not available"
                    handleClick={() => sendEvent({name: CLOSE, reference})}
                    message={
                        <span>
                            {activeMethod?.name} not available at the moment.
                            <br />
                            Please choose another payment method to continue your payment.
                        </span>
                    }
                />
            </div>
        );

    if (timeExpired)
        return (
            <div className="pt-5">
                <TransactionStatus
                    isError
                    title="Account has expired"
                    btnName={`${frame ? 'Close' : ''}`}
                    handleClick={() => sendEvent({name: CLOSE, reference})}
                    message={
                        <span>
                            No payment received for this transaction. <br />
                            Contact <b>support@blusalt.net</b> on any issue related to this
                            transaction.
                        </span>
                    }
                />
            </div>
        );

    return <>{render()}</>;
};

export default CountDownPaymentWrapper;
