import React, {useState, useEffect} from 'react';
import {requestSignal} from 'api';
import {usePayWithBankAccount} from '../PayWithBankAccountContext';
import {titleCase} from 'utils/helpers';

export const BankAccount = ({account = {}, handleClick, loading}) => {
    const {accountNo, bankCode, bankName} = account;
    const [accountName, setAccountName] = useState();
    const {resolveAccount} = usePayWithBankAccount();

    useEffect(
        () =>
            resolveAccount(setAccountName, {
                bank_code: bankCode,
                account_number: accountNo,
            }),
        () => requestSignal.cancel(),
        // eslint-disable-next-line
        []
    );

    return (
        <div className="col-6">
            <div
                className={`account__list ${loading ? 'disabled' : ''}`}
                onClick={handleClick}
                title={accountName}
            >
                <div className="bullet" />
                <div>
                    <p>{accountName ? titleCase(accountName) : '----------------'}</p>
                    <small>
                        {accountNo} ({titleCase(bankName)})
                    </small>
                </div>
            </div>
        </div>
    );
};
