import React from 'react';
import _ from 'lodash';
import TableBody from './TableBody';
import TableHead from './TableHead';
import TableFoot from './TableFoot';

const Table = ({headings, data = [], footings, indexRow, cls, rowClick}) => {
    const sortedData = _.sortBy(data, val => (indexRow ? val[indexRow] : ''));

    const groupedData = _.groupBy(sortedData, val => {
        return indexRow ? val[indexRow]?.charAt(0) : '';
    });

    const indexedData = _.map(groupedData, (_, key) => (
        <span onClick={() => scroll(key)}>{key}</span>
    ));

    let tableData = _.flatMapDeep(
        _.map(groupedData, (value, key) => value.map(val => ({value: val, id: key})))
    );

    tableData = indexRow ? tableData : data;

    const scroll = id => {
        const section = document.querySelector(`#${id}`);
        section.scrollIntoView({behavior: 'smooth', block: 'start'});
    };

    return (
        <>
            <div className={`table__wrapper ${cls}`}>
                <table className="table">
                    {headings && <TableHead headings={headings} />}
                    <TableBody data={tableData} rowClick={rowClick} />
                    {footings && <TableFoot footings={footings} rowClick={rowClick} />}
                </table>

                {indexRow && (
                    <div className="table__index">
                        {indexedData?.map(val => (
                            <p className="text-capitalize text--gray pointer">{val}</p>
                        ))}
                    </div>
                )}
            </div>
        </>
    );
};

export default Table;
