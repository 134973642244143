import {useQuery} from 'react-query';
import {useLocation} from 'react-router-dom';
import {getVirtualAccount} from 'api/routes/wallet';
import {getVersion} from 'utils/helpers';

export const useVirtualAccount = ({reference, config}) => {
    const location = useLocation();
    const {API, apiVersion} = getVersion(location);

    const getVirtualAccountQuery = useQuery(
        ['get-virtual-account', reference],
        async () => {
            try {
                const response = await API.post(
                    getVirtualAccount(reference, apiVersion),
                    {},
                    config
                );
                return response?.data;
            } catch (error) {
                throw error;
            }
        },
        {
            enabled: !!reference,
            retry: false,
            refetchOnWindowFocus: false,
            keepPreviousData: true,
        }
    );

    const {isSuccess, isError: error, data: vaData} = getVirtualAccountQuery ?? {};

    const data = vaData?.data;
    const status = isSuccess && !vaData?.status && !data;
    const isError = error ? error : status;

    return {...getVirtualAccountQuery, isError, data};
};
