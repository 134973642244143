import React from 'react';
import OtpInput from 'react-otp-input';
import './OTPInput.scss';

const OTPInput = ({name, value, separator = '', onChange, numInputs = 6}) => {
    return (
        <div className="otp__input">
            <OtpInput
                name={name}
                value={value}
                onChange={onChange}
                numInputs={numInputs}
                separator={separator}
            />
        </div>
    );
};

export default OTPInput;
