import React from 'react';
import {ReactComponent as Close} from 'assets/images/add.svg';
import {ReactComponent as Secured} from 'assets/images/secured.svg';
import {CLOSE} from 'components/constants';

export const CheckoutWrapper = ({isFrame, isError, children, reference, sendEvent}) => {
    return (
        <>
            <div className={`checkout__content ${isError ? 'error' : ''}`}>
                {children}
                {isFrame && (
                    <div
                        className="close__checkout"
                        onClick={() => sendEvent({name: CLOSE, reference})}
                    >
                        <Close />
                    </div>
                )}
            </div>

            <div className={`checkout__footer ${!isFrame ? 'no__frame' : ''}`}>
                <p>
                    <Secured width={18} height={18} className="me-1 mb-1" /> Secured by{' '}
                    <span>Blusalt</span>
                </p>
            </div>
        </>
    );
};

export default CheckoutWrapper;
