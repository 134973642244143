import TableRow from './TableRow';

const TableFoot = ({footings, rowClick}) => (
    <tfoot className="table__foot">
        {footings?.map((val, i) => (
            <TableRow key={i} id={val?.id || i} rowValues={val?.value || val} rowClick={rowClick} />
        ))}
    </tfoot>
);

export default TableFoot;
