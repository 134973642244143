import React from 'react';
import {BankAccount} from './BackAccount';
import {STEPS, usePayWithBankAccount} from '../PayWithBankAccountContext';
import {ReactComponent as AddIcon} from 'assets/images/add-circle.svg';
import {responseStatus} from 'components/constants';

export const AccountList = () => {
    const {
        loading,
        bankDetails = {},
        updateCurrentState,
        initiateCharge,
        setVerifyAccountStatus,
    } = usePayWithBankAccount();
    const {accounts = []} = bankDetails;

    return (
        <>
            <div className="text-center pt-3">
                <span className="g-bold text--gray">Choose bank account to continue</span>
            </div>

            {loading && <div className="my-4 text-center w-100 fw-bold">Please Wait ...</div>}

            <div className="row align-items-stretch pt-4 account__container">
                <div className="col-6">
                    <div
                        className={`account__list add__new align-items-center h-100 ${
                            loading ? 'disabled' : ''
                        }`}
                        onClick={() => {
                            if (!loading) {
                                setVerifyAccountStatus(responseStatus.IDLE);
                                updateCurrentState(STEPS.NEW_ACCOUNT);
                            }
                        }}
                    >
                        <AddIcon className="mx-3" /> <h5 className="m-0">Add new bank account</h5>
                    </div>
                </div>
                {accounts?.map(account => (
                    <BankAccount
                        key={`${account?.accountNo}${account?.bankCode}`}
                        account={account}
                        handleClick={() => {
                            if (!loading) {
                                setVerifyAccountStatus(responseStatus.IDLE);
                                initiateCharge({
                                    bankCode: account?.bankCode,
                                    bankName: account?.bankName,
                                    accountNumber: account?.accountNo,
                                    narration: `${account?.accountNo} ${account?.bankName}`,
                                });
                            }
                        }}
                        loading={loading}
                    />
                ))}
            </div>
        </>
    );
};
