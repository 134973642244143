import React, {createContext, useState} from 'react';
import {InvoiceLayout} from './LayoutSC';
import Header from './Header/Header';
import {useMemo} from 'react';
// import { colors } from "../extra";

export const InvoiceContext = createContext({});

const Layout = ({children, colors, logoURL}) => {
    const [meta, setMeta] = useState({});
    const values = useMemo(() => ({meta, setMeta}), [meta]);

    return (
        <InvoiceLayout colors={colors} className="body__wrapper bg--primary">
            <InvoiceContext.Provider value={values}>
                <section className="main">
                    <Header LogoURL={logoURL} />
                    <section className="content">{children}</section>
                </section>
            </InvoiceContext.Provider>
        </InvoiceLayout>
    );
};

export default Layout;
