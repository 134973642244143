import {useState} from 'react';
import {Modal, ModalFooter, ModalHeader, ModalBody} from 'reactstrap';
import OTPInput from 'utils/OTPInput/OTPInput';
import CustomSpinner from 'utils/Spinner/Spinner';
import {getError} from 'utils/helpers';
import Alert from 'utils/Alert/Alert';
import {activateSubscription} from 'api/routes/wallet';
import TransactionStatus from 'components/Checkouts/Checkout/common/TransactionStatus';

const SubscriptionOTPModal = ({isOpen, toggle, automate, paymentData}) => {
    const [errorMsg, setErrorMsg] = useState();
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [otpCode, setOtpCode] = useState();

    const {reference, API, apiVersion} = paymentData;
    const phoneNo = paymentData?.metadata?.customer?.phone;

    const setError = message => {
        setLoading(false);
        setErrorMsg(message);
    };

    const handleSubmit = async () => {
        try {
            setLoading(true);
            setErrorMsg();
            const response = await API.post(
                `${apiVersion}${activateSubscription(reference)}`,
                {
                    automate: automate,
                    otp: otpCode,
                },
                {headers: {Authorization: paymentData?.publicKey}}
            );

            const {status, data} = response || {};

            if (status === 200 && (data.status || data.success)) {
                setLoading(false);
                setSuccess(true);
            } else setError(data?.message);
        } catch (error) {
            setError(getError(error.response?.data || error.response));
            setSuccess(false);
        }
    };

    return (
        <Modal toggle={toggle} isOpen={isOpen} centered={true} keyboard={false} backdrop="static">
            <ModalHeader toggle={toggle} className="border-0" />
            <ModalBody>
                {success ? (
                    <TransactionStatus
                        title="Payment Successful"
                        message="Subscription activation successful"
                        handleClick={toggle}
                        btnName="Close"
                        cls="py-5"
                    />
                ) : (
                    <div className="col-12 col-sm-11 m-auto">
                        {errorMsg && (
                            <Alert msg={errorMsg} cls="px-5 mt-1" onClose={() => setErrorMsg()} />
                        )}
                        <p className="fs-3 fw-bold mb-5">Enter the OTP code sent to you</p>
                        <p className="fs-4 mb-5">
                            A 6-digit code has been sent to your email and your phone number (
                            {`${phoneNo.slice(0, 4)} ${phoneNo.slice(4, 6)} *** *** ${phoneNo.slice(
                                -2
                            )}`}
                            ) enter the code to complete your transaction.
                        </p>
                        <div className="mb-5">
                            <OTPInput
                                name="OTPInput"
                                value={otpCode}
                                onChange={value => setOtpCode(value)}
                            />
                        </div>
                    </div>
                )}
            </ModalBody>
            {!success && (
                <ModalFooter>
                    <div className="w-100 p-3 d-flex gap-3">
                        <button className="btn btn--gray rounded-0" onClick={toggle}>
                            Cancel
                        </button>

                        <button
                            className="btn btn--secondary rounded-0"
                            disabled={!otpCode || otpCode?.length < 6 || loading}
                            onClick={handleSubmit}
                        >
                            {loading ? <CustomSpinner /> : 'Complete Payment'}
                        </button>
                    </div>
                </ModalFooter>
            )}
        </Modal>
    );
};

export default SubscriptionOTPModal;
