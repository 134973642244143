import React, {useRef} from 'react';
import './CustomInput.scss';

export const CustomCheckBox = ({label, checked, name, secondary, onClick, labelCls, cls}) => {
    const checkboxEl = useRef(null);
    const isSecondary = secondary ? ' secondary' : '';

    return (
        <label className={`custom-check-box${isSecondary} ${cls}`}>
            <input
                type="checkbox"
                ref={checkboxEl}
                className="pointer"
                name={name}
                checked={checked}
                onChange={() => {}}
                onClick={onClick}
            />
            {label && (
                <label
                    onClick={() => checkboxEl.current.click()}
                    htmlFor={name}
                    className={labelCls}
                >
                    {label}
                </label>
            )}
        </label>
    );
};
