import {useContext} from 'react';
import {getCurrencySymbol, titleCase, toCurrency} from 'utils/helpers';
import {ReactComponent as PlusIcon} from 'assets/images/add.svg';
import {ReactComponent as MinusIcon} from 'assets/images/minus.svg';
import {PaymentPageContext} from './PaymentPageContainer';

const ProductItem = ({prod, customer_edit_product_quantity}) => {
    const {setFormValues} = useContext(PaymentPageContext);
    const [minProdQuantity, maxProdQuantity] = prod?.range ? prod?.range?.split('-') : [];

    const handleClick = isAdd => {
        if (isAdd && prod.quantity < parseInt(maxProdQuantity)) {
            setFormValues(prev => ({
                ...prev,
                products: prev.products?.map(product =>
                    product?.reference === prod?.reference
                        ? {...product, quantity: product.quantity + 1}
                        : product
                ),
            }));
        }
        if (!isAdd && prod.quantity > parseInt(minProdQuantity)) {
            setFormValues(prev => ({
                ...prev,
                products: prev.products?.map(product =>
                    product?.reference === prod?.reference
                        ? {...product, quantity: product.quantity - 1}
                        : product
                ),
            }));
        }
    };
    return (
        <div className="my-4 d-flex align-items-center ">
            <img src={prod?.image_urls[0]} alt="" width="74px" height="66px" className="col-2" />
            <div className="col-5 ms-4">
                <div className="my-2">
                    <h5 className="heading-1">{titleCase(prod?.name)}</h5>
                    {customer_edit_product_quantity && (
                        <h5 className="heading-1">
                            {getCurrencySymbol(prod?.currency)} {toCurrency(prod?.amount)}
                        </h5>
                    )}
                </div>
            </div>
            {customer_edit_product_quantity && (
                <div className="quantity-counter d-flex align-items-center justify-content-between ">
                    <MinusIcon
                        onClick={() => handleClick(false)}
                        className={`${
                            prod?.quantity === +minProdQuantity ? 'disabled' : 'pointer'
                        } `}
                    />

                    <span className="count"> {prod?.quantity}</span>
                    <PlusIcon
                        onClick={() => handleClick(true)}
                        className={`${
                            prod?.quantity === +maxProdQuantity ? 'disabled' : 'pointer'
                        } plus`}
                    />
                </div>
            )}
            <div className="col-3 justify-self-end heading-1 text-end pe-2 pe-lg-5">
                {getCurrencySymbol(prod?.currency)} {toCurrency(prod?.quantity * prod?.amount)}
            </div>
        </div>
    );
};

export default ProductItem;
